import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './css/feed.css';
import { Dropdown, DropdownUser } from './selecteur';
import { CircularProgress, Tooltip, IconButton, Snackbar, Checkbox, Box } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

// Fonction pour normaliser les caractères accentués
const normalizeString = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
};

const Feed = () => {
    const [feedView, setFeedView] = useState('Most Recent');
    const [feedViewInspiration, setFeedViewInspiration] = useState('Most Recent');
    const [posts, setPosts] = useState([]);
    const [userAdmin, setUserAdmin] = useState(false);
    const [users, setUsers] = useState([]); 
    const [selectedUser, setSelectedUser] = useState("all");
    const [selectedCategory, setSelectedCategory] = useState("all");
    const [selectedLocation, setSelectedLocation] = useState("all");
    const [minImpressions, setMinImpressions] = useState(0);
    const [availableLocations, setAvailableLocations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const viewMap = {
        'Most Recent': 'newest',
        'Oldest': 'oldest',
        'Most Impressions': 'most-impressions',
        'Least Impressions': 'least-impressions',
        'Most Comments': 'most-comments',
        'Least Comments': 'least-comments',
        'Most Likes': 'most-likes',
        'Least Likes': 'least-likes'
    };
    const viewMapIns = {
        'Most Recent': 'newest',
        'Oldest': 'oldest'
    }

    useEffect(() => {
        fetchUsers();
        fecthInfoUser();
        fetchLocations();
    }, []);

    useEffect(() => {
        fetchPosts()
    }, [selectedUser, selectedCategory, feedView, feedViewInspiration, selectedLocation, minImpressions]);

    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/users-all`, {
                withCredentials: true
            });
            setUsers(response.data.users);
        } catch (error) {
            console.error('Error fetching users', error);
        }
    };

    const fetchLocations = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/locations`, {
                withCredentials: true
            });
            setAvailableLocations(response.data.locations);
        } catch (error) {
            console.error('Error fetching locations', error);
        }
    };
    const fecthInfoUser = async () => {
        try {
            const response = await fetch(`${apiUrl}/api/user-info`, {
                method: 'GET',
                credentials: 'include',
            });
    
            if (!response.ok) {
                throw new Error('Failed to fetch user info');
            }
    
            const data = await response.json();
            setUserAdmin(data.user.role === 'admin');
        } catch (error) {
            console.error('Error fetching user info', error);
        }
    }
    const fetchPosts = async () => {
        setLoading(true);
        switch (selectedCategory) {
            case "all":
                try {
                    const response = await axios.get(`${apiUrl}/api/posts`, {
                        params: {
                            sort: viewMap[feedView],
                            userId: selectedUser === "all" ? null : selectedUser,
                            location: selectedLocation
                        },
                        withCredentials: true
                    });
                    setPosts(response.data);
                } catch (error) {
                    console.error('Error fetching posts', error);
                } finally {
                    setLoading(false);
                }
                break;
            case "inspiration":
                try {
                    const response = await axios.get(`${apiUrl}/api/inspiration`, {
                        params: {
                            sort: viewMap[feedViewInspiration]
                        },
                        withCredentials: true
                    });
                    setPosts(response.data);
                } catch (error) {
                    console.error('Error fetching posts', error);
                } finally {
                    setLoading(false);
                }
                break;
        }
    };

    const handleCategoryChange = (value) => {
        switch (value) {
            case "all":
                setSelectedCategory(value);
                setSelectedLocation("all")
                break;
            case "inspiration":
                setSelectedCategory(value);
                setSelectedLocation("all")
                break;
            default:
                setSelectedCategory(value);
                setSelectedLocation("all")
        }
    };

    const handleFeedViewChange = (event) => {
        switch (selectedCategory) {
            case "all":
                setFeedView(event.target.value);
                break;
            case "inspiration":
                setFeedViewInspiration(event.target.value);
                break;
            default:
                setFeedView(event.target.value);
        }
    };

    // Filtrer les utilisateurs en fonction de la location sélectionnée
    const getFilteredUsers = () => {
        if (selectedLocation === "all") return users;
        return users.filter(user => user.location === selectedLocation);
    };

    const handleCopy = async (url) => {
        try {
            const response = await axios.get(`${apiUrl}/api/scrape-linkedin-content`, {
                params: { url },
                withCredentials: true
            });
            if (response.data && response.data.content) {
                await navigator.clipboard.writeText(response.data.content);
                setSnackbarOpen(true);
            }
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };

    const handleAddToInspiration = async (link) => {
        try {
            await axios.post(`${apiUrl}/api/inspiration`, { link });
            setSnackbarOpen(true);
            fetchPosts(); // Refresh posts after adding to inspiration
        } catch (error) {
            console.error('Error adding to inspiration:', error);
        }
    };

    const handleDisplayToggle = async (post, display) => {
        try {
            console.log('Toggling display for post:', post.link, 'to:', display);
            const response = await axios.post(`${apiUrl}/api/posts/display`, { 
                postUrl: post.link,
                display: display 
            }, {
                withCredentials: true
            });
            console.log('Toggle response:', response.data);
            await fetchPosts(); // Refresh posts after updating display status
        } catch (error) {
            console.error('Error updating post display:', error);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const formatNumber = (num) => {
        if (!num) return '0';
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    return (
        <div className='base'>
            <div className='Feed'>
                <div className='title'>
                    <h1 className='my-5'>Feed</h1>
                    <div className='selecter-box' style={{ display: 'flex', gap: '10px' }}>
                        {selectedCategory === "all" && (
                            <>
                                <Dropdown
                                    label="All Entities"
                                    options={[
                                        { label: "All Entities", value: "all" },
                                        ...availableLocations.map(location => ({
                                            label: location,
                                            value: location
                                        }))
                                    ]}
                                    onSelect={(location) => {
                                        setSelectedLocation(location);
                                        setSelectedUser("all");
                                    }}
                                />
                                <DropdownUser
                                    label="Select a user"
                                    options={[
                                        { label: "All Users", value: "all" },
                                        ...getFilteredUsers()
                                            .map(user => ({
                                                label: `${user.givenName} ${user.familyName}`,
                                                value: user.id,
                                                searchString: normalizeString(`${user.givenName} ${user.familyName}`)
                                            }))
                                    ]}
                                    onSelect={(userId) => {
                                        setSelectedUser(userId);
                                    }}
                                    value={selectedUser}
                                    normalizeSearch={normalizeString}
                                />
                            </>
                        )}

                        <Dropdown
                            label="All"
                            options={[
                                { label: 'All', value: 'all' },
                                { label: 'Inspiration', value: 'inspiration' }
                            ]}
                            onSelect={handleCategoryChange}
                        />
                        <div style={{ marginLeft: '10px' }}>
                            <input
                                type="number"
                                value={minImpressions === 0 ? '' : minImpressions}
                                onChange={(e) => {
                                    const value = e.target.value === '' ? 0 : parseInt(e.target.value);
                                    setMinImpressions(Math.max(0, value));
                                }}
                                placeholder="Min. impressions"
                                style={{
                                    padding: "6px",
                                    fontSize: "11px",
                                    borderRadius: "4px",
                                    color: "#000000",
                                    height: "3.34em",
                                    border: "1px solid rgb(204, 204, 204)",
                                    width: "150px",
                                    outline: "none"
                                }}
                            />
                        </div>
                    </div>
                </div>
                
                <div className="container" style={{ position: "absolute", left: "17em", top: "15em", width: "80%" }}>


                    {loading ? (
                        <div style={{textAlign:"center", width:"80em", marginBottom:"5em", display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <CircularProgress />
                        </div>
                    ) : posts.length === 0 ? (
                        <div className="text-center text-gray-500 mt-8">
                            No posts found
                        </div>
                    ) : (
                        <div className="post">
                            <div className='view-selection'>
                                <label htmlFor="feedView">Select the feed view: </label>
                                {selectedCategory === "inspiration" ? (
                                    <select id="feedView" className='feedView' value={feedViewInspiration} onChange={handleFeedViewChange}>
                                        <option value="Most Recent">Most Recent</option>
                                        <option value="Oldest">Oldest</option>
                                    </select>
                                ) : (
                                    <select id="feedView" className='feedView' value={feedView} onChange={handleFeedViewChange}>
                                        <option value="Most Recent">Most Recent</option>
                                        <option value="Oldest">Oldest</option>
                                        <option value="Most Impressions">Most Impressions</option>
                                        <option value="Least Impressions">Least Impressions</option>
                                        <option value="Most Comments">Most Comments</option>
                                        <option value="Least Comments">Least Comments</option>
                                        <option value="Most Likes">Most Likes</option>
                                        <option value="Least Likes">Least Likes</option>
                                    </select>
                                )}
                            </div>
                            {posts
                                .filter(post => !minImpressions || post.impressions >= minImpressions)
                                .filter(post => userAdmin || post.display !== false)
                                .map((post, index) => (
                                <div style={{ position: 'relative', marginBottom: '20px' }}>
                                    <div key={index} style={{ position: 'relative' }}>
                                        <Tooltip title="Copy content">
                                            <IconButton 
                                                onClick={() => handleCopy(post.link)}
                                                style={{ 
                                                    position: 'absolute',
                                                    top: '5px',
                                                    right: '14px',
                                                    backgroundColor: 'white',
                                                    padding: '8px',
                                                    zIndex: 1000
                                                }}
                                            >
                                                <ContentCopyIcon  style={{ fontSize: '12px' }}/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    {userAdmin && (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                padding: '4px 8px',
                                                borderRadius: '4px',
                                                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                                fontSize: '12px',
                                                fontWeight: 500,
                                                letterSpacing: '0.3px',
                                                color: '#1a1a1a',
                                                boxShadow: '0 2px 4px rgba(0,0,0,0.08), 0 0 1px rgba(0,0,0,0.08)',
                                                border: '1px solid rgba(0,0,0,0.04)',
                                                transition: 'all 0.2s ease',
                                                zIndex: 1000,
                                                '&:hover': {
                                                    backgroundColor: 'rgba(255, 255, 255, 1)',
                                                    boxShadow: '0 3px 6px rgba(0,0,0,0.12), 0 0 1px rgba(0,0,0,0.12)',
                                                }
                                            }}
                                        >
                                            <Checkbox
                                                size="small"
                                                checked={post.display !== false}
                                                onChange={(e) => handleDisplayToggle(post, e.target.checked)}
                                                style={{ 
                                                    padding: '2px',
                                                    color: '#2196f3',
                                                    '&.Mui-checked': {
                                                        color: '#2196f3',
                                                    }
                                                }}
                                            />
                                            Display
                                        </Box>
                                    )}
                                    <iframe
                                        key={index}
                                        src={post && post.link}
                                        height="583"
                                        width="504"
                                        allowFullScreen
                                        title={`Embedded Post ${index}`}
                                        loading="lazy"
                                    />
                                    <div className="impression-counter">
                                        {formatNumber(post.impressions)} impressions
                                    </div>
                                </div>
                            ))}
                            <Snackbar
                                open={snackbarOpen}
                                autoHideDuration={2000}
                                onClose={handleSnackbarClose}
                                message="Content copied to clipboard!"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Feed;
