// Dashboard.js
import React, { useState } from 'react';
import Sidebar from './Sidebar';
import Home from './Home';
import Feed from './Feed';
import Ranking from './Ranking';
import Awards from './Awards';
import Tips from './Tips';
import Library from './Library';
import Settings from './Settings';
import Stats from './Stats';
import Reformer from './Reformer';
import Illustrator from './Illustrator';
import Sales from './Sales';
import './css/dashboard.css';
import Popup from './popup';
import useUserInfo from './userProfil';
function Dashboard() {
  const [activePage, setActivePage] = useState('Home');
  const { userInfo } = useUserInfo();

  // Fonction pour vérifier si l'utilisateur a accès à une fonctionnalité
  const hasAccess = (requiredRole) => {
    if (!userInfo) return false;
    
    const roleHierarchy = {
      'admin': 4,
      'manager': 3,
      'normal': 2,
      'consultant': 1
    };

    const userRoleLevel = roleHierarchy[userInfo?.role || 'consultant'];
    const requiredRoleLevel = roleHierarchy[requiredRole];

    return userRoleLevel >= requiredRoleLevel;
  };

  const renderContent = () => {
    switch (activePage) {
      case 'Home':
        return <Home />;
      case 'Feed':
        return <Feed />;
      case 'Ranking':
        return <Ranking />;
      case 'Awards':
        return <Awards />;
      case 'Reformer':
        return <Reformer />;
      case 'Illustrator':
          return <Illustrator />;
      case 'Tips':
        return <Tips />;
      case 'Library':
        return <Library />;
      case 'Settings':
        return <Settings />;
      case 'Stats':
        return hasAccess('manager') ? <Stats /> : <Home />;
      case 'Sales':
        return hasAccess('admin') ? <Sales /> : <Home />;
      default:
        return <Ranking />;
    }
  };

  return (
    <div className="dashboard">
      <Popup />
      <Sidebar activePage={activePage} setActivePage={setActivePage} />
      <div className="content">
        {renderContent()}
      </div>
    </div>
  );
}

export default Dashboard;
