import React, { useState } from 'react';
import { Home, HomeHover, Awards, AwardsHover, Settings, Feed, FeedHover, Ranking, RankingHover, Tips, TipsHover, Library, LibraryHover, Reformer, ReformerHover, Illustrator, IllustratorHover, StatsHover, Stats } from '../img/icons';
import { TrendingUp } from '@material-ui/icons';
import './css/sidebar.css';
import logoEskimoz from '../img/logoEskimoz.svg';
import useUserInfo from './userProfil'; 


function Sidebar({ activePage, setActivePage }) {
  const [hoveredItem, setHoveredItem] = useState(activePage);
  const { userInfo, error } = useUserInfo(); 
  
  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!userInfo) {
      return <div>Loading...</div>;
  } 

  const handleClick = (page) => {
    setActivePage(page);
  };

  const handleMouseEnter = (item) => {
    setHoveredItem(item);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  // Fonction pour vérifier si l'utilisateur a accès à une fonctionnalité
  const hasAccess = (requiredRole) => {
    const roleHierarchy = {
      'admin': 4,
      'manager': 3,
      'normal': 2,
      'consultant': 1
    };

    const userRoleLevel = roleHierarchy[userInfo?.role || 'consultant'];
    const requiredRoleLevel = roleHierarchy[requiredRole];

    return userRoleLevel >= requiredRoleLevel;
  };

  const getItemPosition = (item) => {
    // Liste de tous les éléments possibles avec leur niveau d'accès requis
    const menuItems = [
      { name: 'Home', role: 'consultant' },
      { name: 'Feed', role: 'normal' },
      { name: 'Ranking', role: 'normal' },
      { name: 'Sales', role: 'admin' },
      { name: 'Stats', role: 'manager' },
      { name: 'Awards', role: 'normal' },
      { name: 'Library', role: 'normal' },
      { name: 'Tips', role: 'normal' }
    ];

    // Filtrer les éléments accessibles selon le rôle de l'utilisateur
    const visibleItems = menuItems
      .filter(menuItem => hasAccess(menuItem.role))
      .map(menuItem => menuItem.name);

    const index = visibleItems.indexOf(item);
    if (index === -1) return 0;
    return index * 50;
  };

  return (
    <div className="sidebar">
      <div className="logoEsk">
        <img src={logoEskimoz} alt="Logo" className="logo" />
      </div>
      
      <div className="profile">
        <img src={userInfo.photo} alt="Profile" />
        <p style={{ fontWeight: '500' }}>{userInfo.givenName} {userInfo.familyName}</p>
      </div>
      
      <ul className="menu">
        <div className="indicator" style={{ top: `${getItemPosition(hoveredItem || activePage)}px` }}></div>
        <li>
          <button
            className={activePage === 'Home' ? 'active' : ''}
            onClick={() => handleClick('Home')}
            onMouseEnter={() => handleMouseEnter('Home')}
            onMouseLeave={handleMouseLeave}
          >
            {hoveredItem === 'Home' || activePage === 'Home' ? <HomeHover className="icon" /> : <Home className="icon" />} Home
          </button>
        </li>
        {hasAccess('normal') && (
          <>
            <li>
              <button
                className={activePage === 'Feed' ? 'active' : ''}
                onClick={() => handleClick('Feed')}
                onMouseEnter={() => handleMouseEnter('Feed')}
                onMouseLeave={handleMouseLeave}
              >
                {hoveredItem === 'Feed' || activePage === 'Feed' ? <FeedHover className="icon" /> : <Feed className="icon" />} Feed
              </button>
            </li>
            <li>
              <button
                className={activePage === 'Ranking' ? 'active' : ''}
                onClick={() => handleClick('Ranking')}
                onMouseEnter={() => handleMouseEnter('Ranking')}
                onMouseLeave={handleMouseLeave}
              >
                {hoveredItem === 'Ranking' || activePage === 'Ranking' ? <RankingHover className="icon" /> : <Ranking className="icon" />} Ranking
              </button>
            </li>
          </>
        )}
        {hasAccess('admin') && (
          <li>
            <button
              className={activePage === 'Sales' ? 'active' : ''}
              onClick={() => handleClick('Sales')}
              onMouseEnter={() => handleMouseEnter('Sales')}
              onMouseLeave={handleMouseLeave}
            >
              <TrendingUp className="icon" style={{width:"15px", height:"15px" }}/> Sales
            </button>
          </li>
        )}
        {hasAccess('manager') && (
          <li>
            <button
              className={activePage === 'Stats' ? 'active' : ''}
              onClick={() => handleClick('Stats')}
              onMouseEnter={() => handleMouseEnter('Stats')}
              onMouseLeave={handleMouseLeave}
            >
              {hoveredItem === 'Stats' || activePage === 'Stats' ? <StatsHover className="icon" /> : <Stats className="icon" />} Stats
            </button>
          </li>
        )}
        {hasAccess('normal') && (
          <>
            <li>
              <button
                className={activePage === 'Awards' ? 'active' : ''}
                onClick={() => handleClick('Awards')}
                onMouseEnter={() => handleMouseEnter('Awards')}
                onMouseLeave={handleMouseLeave}
              >
                {hoveredItem === 'Awards' || activePage === 'Awards' ? <AwardsHover className="icon" /> : <Awards className="icon" />} Awards
              </button>
            </li>
            {/* <li>
              <button
                className={activePage === 'Reformer' ? 'active' : ''}
                onClick={() => handleClick('Reformer')}
                onMouseEnter={() => handleMouseEnter('Reformer')}
                onMouseLeave={handleMouseLeave}
              >
                {hoveredItem === 'Reformer' || activePage === 'Reformer' ? <ReformerHover className="icon" /> : <Reformer className="icon" />} Reformer
              </button>
            </li>
            <li>
              <button
                className={activePage === 'Illustrator' ? 'active' : ''}
                onClick={() => handleClick('Illustrator')}
                onMouseEnter={() => handleMouseEnter('Illustrator')}
                onMouseLeave={handleMouseLeave}
              >
                {hoveredItem === 'Illustrator' || activePage === 'Illustrator' ? <IllustratorHover className="icon" /> : <Illustrator className="icon" />} Illustrator
              </button>
            </li> */}
            <li>
              <button
                className={activePage === 'Library' ? 'active' : ''}
                onClick={() => handleClick('Library')}
                onMouseEnter={() => handleMouseEnter('Library')}
                onMouseLeave={handleMouseLeave}
              >
                {hoveredItem === 'Library' || activePage === 'Library' ? <LibraryHover className="icon" /> : <Library className="icon" />} Library
              </button>
            </li>
            <li>
              <button
                className={activePage === 'Tips' ? 'active' : ''}
                onClick={() => handleClick('Tips')}
                onMouseEnter={() => handleMouseEnter('Tips')}
                onMouseLeave={handleMouseLeave}
              >
                {hoveredItem === 'Tips' || activePage === 'Tips' ? <TipsHover className="icon" /> : <Tips className="icon" />} Tips
              </button>
            </li>
          </>
        )}
      </ul>
      
      <div className="settings">
        {hasAccess('normal') && (
          <button
            className={activePage === 'Settings' ? 'active' : ''}
            onClick={() => handleClick('Settings')}
          >
            <Settings className="icon" /> Settings
          </button>
        )}
      </div>
    </div>
  );
}

export default Sidebar;
