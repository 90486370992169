import { create } from 'zustand';

const useFileStore = create((set, get) => ({
  // États
  files: [],
  allFiles: [], // Stockage de tous les fichiers
  selectedCategory: 'all', // Pour la langue
  selectedRealCategory: 'all', // Pour la catégorie
  loading: false,
  error: null,
  selectedFile: null,
  showModal: false,
  showDeleteConfirm: false,
  fileToUpload: null,
  tag: '',
  newName: '',
  newRealCategory: '',
  newCategory: '', // Pour la langue
  uploading: false,
  snackbar: {
    open: false,
    message: '',
    severity: 'success'
  },
  errors: {
    name: false,
    category: false,
    file: false,
  },
  links: [],
  newLink: '',
  showLinksModal: false,

  // Actions
  setFiles: (files) => set({ files, allFiles: files }),
  setSelectedCategorySimple: (category) => {
    set((state) => {
      console.log('Changing language to:', category);
      console.log('All files:', state.allFiles);
      
      if (category === 'all') {
        return { selectedCategory: category, files: state.allFiles };
      }
      
      const filteredFiles = state.allFiles.filter(file => 
        file.metadata.category === category
      );
      
      console.log('Filtered files:', filteredFiles);
      return { selectedCategory: category, files: filteredFiles };
    });
  },
  setSelectedCategory: (category) => {
    set((state) => {
      console.log('Changing language to:', category);
      console.log('All files:', state.allFiles);
      
      let filteredFiles = state.allFiles;
      
      // Filtre par langue si une langue est sélectionnée
      if (category !== 'all') {
        filteredFiles = filteredFiles.filter(file => file.metadata.category === category);
      }
      
      // Filtre par catégorie si une catégorie est sélectionnée
      if (state.selectedRealCategory !== 'all') {
        filteredFiles = filteredFiles.filter(file => file.metadata.realCategory === state.selectedRealCategory);
      }
      
      console.log('Filtered files:', filteredFiles);
      return { selectedCategory: category, files: filteredFiles };
    });
  },
  setSelectedRealCategory: (category) => {
    set((state) => {
      console.log('Changing category to:', category);
      console.log('All files:', state.allFiles);
      
      let filteredFiles = state.allFiles;
      
      // Filtre par catégorie si une catégorie est sélectionnée
      if (category !== 'all') {
        filteredFiles = filteredFiles.filter(file => file.metadata.realCategory === category);
      }
      
      // Filtre par langue si une langue est sélectionnée
      if (state.selectedCategory !== 'all') {
        filteredFiles = filteredFiles.filter(file => file.metadata.category === state.selectedCategory);
      }
      
      console.log('Filtered files:', filteredFiles);
      return { selectedRealCategory: category, files: filteredFiles };
    });
  },
  setLoading: (loading) => set({ loading }),
  setError: (error) => set({ error }),
  setSelectedFile: (file) => set({ selectedFile: file }),
  setShowModal: (show) => set({ showModal: show }),
  setShowDeleteConfirm: (show) => set({ showDeleteConfirm: show }),
  setFileToUpload: (file) => set({ fileToUpload: file }),
  setTag: (tag) => set({ tag }),
  setNewName: (name) => set({ newName: name }),
  setNewCategory: (category) => set({ newCategory: category }), // Pour la langue
  setNewRealCategory: (category) => set({ newRealCategory: category }), // Pour la catégorie
  setUploading: (uploading) => set({ uploading }),
  setSnackbar: (snackbar) => set({ snackbar }),
  setErrors: (errors) => set({ errors }),
  setLinks: (links) => set({ links }),
  setNewLink: (link) => set({ newLink: link }),
  setShowLinksModal: (show) => set({ showLinksModal: show }),
  resetFilters: () => set((state) => ({
    selectedCategory: 'all',
    selectedRealCategory: 'all',
    files: state.allFiles
  })),
  // Mise à jour des liens d'un fichier
  updateFileLinks: async (fileId, links) => {
    const apiUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3000';
    console.log('Updating links for file:', fileId);
    console.log('New links:', links);
    
    try {
      const response = await fetch(`${apiUrl}/api/files/${fileId}/links`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ links }),
      });

      if (!response.ok) {
        throw new Error('Failed to update file links');
      }

      // Mettre à jour l'état local directement
      set(state => {
        console.log('Current state:', state);
        
        const updatedFiles = state.files.map(file => {
          if (file._id === fileId) {
            console.log('Updating file:', file._id);
            const updatedFile = { ...file, metadata: { ...file.metadata, links } };
            console.log('Updated file:', updatedFile);
            return updatedFile;
          }
          return file;
        });

        const updatedAllFiles = state.allFiles.map(file => {
          if (file._id === fileId) {
            return { ...file, metadata: { ...file.metadata, links } };
          }
          return file;
        });

        console.log('Updated files:', updatedFiles);

        // Mettre à jour aussi le fichier sélectionné si c'est celui-ci
        const updatedSelectedFile = state.selectedFile && state.selectedFile._id === fileId
          ? { ...state.selectedFile, metadata: { ...state.selectedFile.metadata, links } }
          : state.selectedFile;

        return {
          files: updatedFiles,
          allFiles: updatedAllFiles,
          selectedFile: updatedSelectedFile,
          showLinksModal: false,
          links: [],
          newLink: '',
          snackbar: {
            open: true,
            message: 'Links updated successfully',
            severity: 'success'
          }
        };
      });

    } catch (error) {
      console.error('Error updating file links:', error);
      set({
        snackbar: {
          open: true,
          message: 'Failed to update links',
          severity: 'error'
        }
      });
    }
  },

  // Fetch tous les fichiers une seule fois
  fetchFiles: async () => {
    const apiUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3000';
    try {
      set({ loading: true, error: null });
      const url = `${apiUrl}/api/files`;

      console.log('Fetching all files from:', url);
      const response = await fetch(url);
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Server response:', response.status, errorText);
        throw new Error(`Failed to fetch files: ${response.status} ${errorText}`);
      }

      const data = await response.json();
      if (!Array.isArray(data)) {
        console.error('Received non-array data:', data);
        throw new Error('Invalid data format received from server');
      }

      console.log('Fetched files:', data.length);
      set({ 
        files: data, 
        allFiles: data, 
        loading: false, 
        error: null 
      });
    } catch (err) {
      console.error('Error in fetchFiles:', err);
      set({ 
        error: err.message || 'Failed to load files',
        loading: false,
        files: [],
        allFiles: []
      });
    }
  },

  getRealCategories: () => {
    const { allFiles } = get();
    return [
      { label: 'All', value: 'all' },
      ...Array.from(new Set(allFiles.map(file => file.metadata.realCategory)))
        .filter(Boolean)
        .map(category => ({ label: category, value: category }))
    ];
  },

  getLanguageCategories: () => [
    { label: 'All', value: 'all' },
    { label: 'French', value: 'French' },
    { label: 'English', value: 'English' },
    { label: 'Spanish', value: 'Spanish' },
    { label: 'Italian', value: 'Italian' },
    { label: 'German', value: 'German' },
  ],

  resetForm: () => set((state) => ({
    fileToUpload: null,
    tag: '',
    newName: '',
    newCategory: '', // Reset de la langue
    newRealCategory: '', // Reset de la catégorie
    showModal: false,
    errors: {
      name: false,
      category: false,
      file: false,
    }
  })),
}));

export default useFileStore;
