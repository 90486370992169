import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Chip,
  Paper,
  Typography,
  Box,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import './css/keywordFilter.css';

const KeywordFilter = () => {
  const [keywords, setKeywords] = useState([]);
  const [newKeyword, setNewKeyword] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    // Load keywords from backend
    fetchKeywords();
  }, []);

  const fetchKeywords = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/settings/keywords`, {
        credentials: 'include'
      });
      if (!response.ok) throw new Error('Failed to fetch keywords');
      const data = await response.json();
      setKeywords(data.keywords);
    } catch (error) {
      console.error('Error fetching keywords:', error);
      setError('Failed to load keywords');
    }
  };

  const handleAddKeyword = async () => {
    if (!newKeyword.trim()) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/settings/keywords`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ keyword: newKeyword.trim() }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to add keyword');
      }

      // Reload keywords
      await fetchKeywords();
      setNewKeyword('');
      setError('');
    } catch (error) {
      console.error('Error adding keyword:', error);
      setError(error.message);
    }
  };

  const handleDeleteKeyword = async (keywordToDelete) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/settings/keywords/${keywordToDelete}`, {
        method: 'DELETE',
        credentials: 'include'
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to delete keyword');
      }

      // Reload keywords
      await fetchKeywords();
      setError('');
    } catch (error) {
      console.error('Error deleting keyword:', error);
      setError(error.message);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleAddKeyword();
    }
  };

  return (
    <Paper className="keyword-filter-container">
      <Typography variant="h6" className="section-title">
        Filter Keywords
      </Typography>
      <Typography variant="body2" className="section-description">
        Add keywords to filter sales page results based on job titles
      </Typography>

      <Box className="keyword-input-container">
        <TextField
          value={newKeyword}
          onChange={(e) => setNewKeyword(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Enter a keyword"
          size="small"
          error={!!error}
          helperText={error}
          className="keyword-input"
        />
        <Button
          onClick={handleAddKeyword}
          variant="contained"
          color="primary"
          className="add-button"
        >
          <AddIcon />
        </Button>
      </Box>

      <Box className="keywords-list">
        {keywords.map((keyword, index) => (
          <Chip
            key={index}
            label={keyword}
            onDelete={() => handleDeleteKeyword(keyword)}
            className="keyword-chip"
            deleteIcon={<DeleteIcon />}
          />
        ))}
      </Box>
    </Paper>
  );
};

export default KeywordFilter;
