import { ReactComponent as Settings } from './settings.svg';
import { ReactComponent as Feed } from './feed.svg';
import { ReactComponent as Ranking } from './ranking.svg';
import { ReactComponent as Tips } from './tips.svg';
import { ReactComponent as TipsHover } from './tipsHover.svg';
import { ReactComponent as Home } from './home.svg';
import { ReactComponent as HomeHover } from './homeHover.svg';
import { ReactComponent as Awards } from './awards.svg';
import { ReactComponent as AwardsHover } from './awardsHover.svg';
import { ReactComponent as FeedHover } from './feedHover.svg';
import { ReactComponent as RankingHover } from './rankingHover.svg';
import { ReactComponent as Reformer } from './reformer.svg';
import { ReactComponent as ReformerHover } from './reformerHover.svg';
import { ReactComponent as Illustrator } from './illustrator.svg';
import { ReactComponent as IllustratorHover } from './illustratorHover.svg';
import { ReactComponent as Library } from './library.svg';
import { ReactComponent as LibraryHover } from './libraryHover.svg';
import { ReactComponent as Stats } from './stats.svg';
import { ReactComponent as StatsHover } from './statsHover.svg';

export {
    Home,
    Awards,
    Settings,
    Feed,
    FeedHover,
    Ranking,
    RankingHover,
    Tips,
    TipsHover,
    AwardsHover,
    Reformer,
    ReformerHover,
    Illustrator,
    IllustratorHover,
    HomeHover,
    Stats,
    StatsHover,
    Library,
    LibraryHover
};