// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.keyword-filter-container {
  padding: 24px;
  margin: 15px 15px 0 15px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1) !important;
  width: calc(100% - 30px);
}

.section-title {
  color: #1a1a1a;
  margin-bottom: 8px !important;
  font-family: 'Volte', sans-serif !important;
}

.section-description {
  color: #666;
  margin-bottom: 24px !important;
  font-family: 'Volte', sans-serif !important;
}

.keyword-input-container {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
  align-items: center;
}

.keyword-input {
  flex: 1 1;
}

.keywords-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.keyword-chip {
  background-color: #f0f0f0 !important;
  border-radius: 16px !important;
  font-family: 'Volte', sans-serif !important;
  color: #333 !important;
  height: 32px !important;
  font-size: 14px !important;
}

.keyword-chip:hover {
  background-color: #e0e0e0 !important;
}

.add-button {
  width: 40px !important;
  height: 40px !important;
  min-width: 40px !important;
  border-radius: 50% !important;
  padding: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/settings/css/keywordFilter.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,wBAAwB;EACxB,kBAAkB;EAClB,mDAAmD;EACnD,wBAAwB;AAC1B;;AAEA;EACE,cAAc;EACd,6BAA6B;EAC7B,2CAA2C;AAC7C;;AAEA;EACE,WAAW;EACX,8BAA8B;EAC9B,2CAA2C;AAC7C;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,aAAa;EACb,eAAe;EACf,QAAQ;AACV;;AAEA;EACE,oCAAoC;EACpC,8BAA8B;EAC9B,2CAA2C;EAC3C,sBAAsB;EACtB,uBAAuB;EACvB,0BAA0B;AAC5B;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,0BAA0B;EAC1B,6BAA6B;EAC7B,qBAAqB;AACvB","sourcesContent":[".keyword-filter-container {\n  padding: 24px;\n  margin: 15px 15px 0 15px;\n  border-radius: 8px;\n  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1) !important;\n  width: calc(100% - 30px);\n}\n\n.section-title {\n  color: #1a1a1a;\n  margin-bottom: 8px !important;\n  font-family: 'Volte', sans-serif !important;\n}\n\n.section-description {\n  color: #666;\n  margin-bottom: 24px !important;\n  font-family: 'Volte', sans-serif !important;\n}\n\n.keyword-input-container {\n  display: flex;\n  gap: 16px;\n  margin-bottom: 24px;\n  align-items: center;\n}\n\n.keyword-input {\n  flex: 1;\n}\n\n.keywords-list {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 8px;\n}\n\n.keyword-chip {\n  background-color: #f0f0f0 !important;\n  border-radius: 16px !important;\n  font-family: 'Volte', sans-serif !important;\n  color: #333 !important;\n  height: 32px !important;\n  font-size: 14px !important;\n}\n\n.keyword-chip:hover {\n  background-color: #e0e0e0 !important;\n}\n\n.add-button {\n  width: 40px !important;\n  height: 40px !important;\n  min-width: 40px !important;\n  border-radius: 50% !important;\n  padding: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
