import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableSortLabel,
    Box,
    CircularProgress,
    ToggleButtonGroup,
    ToggleButton,
    Button
} from '@mui/material';
import { DropdownDate } from './selecteur';
import './css/ranking.css';
import linkedinIcon from '../img/icons/linkedinIcon.png';
import exportExcelIcon from '../img/exportExcel.png';
import * as XLSX from 'xlsx';
import ReactCountryFlag from 'react-country-flag';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const Stats = () => {
    const [stats, setStats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [orderBy, setOrderBy] = useState('views');
    const [order, setOrder] = useState('desc');
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedEntity, setSelectedEntity] = useState('All');
    const [entities, setEntities] = useState(['All']);
    const [viewType, setViewType] = useState('users'); // 'users' ou 'entities'
    const [userConnections, setUserConnections] = useState({}); // Stockage des connexions par userId
    const [userLikers, setUserLikers] = useState({}); // Stockage des likers par userId
    const [keywords, setKeywords] = useState([]);

    // Generate years for filter (from 2023 to current year)
    const years = Array.from(
        { length: new Date().getFullYear() - 2023 }, 
        (_, i) => ({ value: 2024 + i, label: (2024 + i).toString() })
    );
    
    const months = [
        { value: 0, label: 'All' },
        { value: 1, label: 'January' },
        { value: 2, label: 'February' },
        { value: 3, label: 'March' },
        { value: 4, label: 'April' },
        { value: 5, label: 'May' },
        { value: 6, label: 'June' },
        { value: 7, label: 'July' },
        { value: 8, label: 'August' },
        { value: 9, label: 'September' },
        { value: 10, label: 'October' },
        { value: 11, label: 'November' },
        { value: 12, label: 'December' }
    ];

    useEffect(() => {
        fetchStats();
        fetchKeywords();
    }, [selectedMonth, selectedYear, selectedEntity, viewType]);

    const fetchStats = async () => {
        setLoading(true);
        try {
            if (selectedMonth === 0) {
                // Si "All" est sélectionné, faire les requêtes pour l'année en cours et l'année précédente
                const currentYearPromises = Array.from({ length: 12 }, (_, i) => 
                    axios.get(`${apiUrl}/api/users-stats`, {
                        params: {
                            month: i + 1,
                            year: selectedYear,
                            entity: selectedEntity,
                            viewType: viewType
                        },
                        withCredentials: true
                    })
                );

                const previousYearPromises = Array.from({ length: 12 }, (_, i) => 
                    axios.get(`${apiUrl}/api/users-stats`, {
                        params: {
                            month: i + 1,
                            year: selectedYear - 1,
                            entity: selectedEntity,
                            viewType: viewType
                        },
                        withCredentials: true
                    })
                );

                const [currentYearResponses, previousYearResponses] = await Promise.all([
                    Promise.all(currentYearPromises),
                    Promise.all(previousYearPromises)
                ]);

                const currentYearStats = currentYearResponses.map(response => response.data.stats).filter(stats => stats.length > 0);
                const previousYearStats = previousYearResponses.map(response => response.data.stats).filter(stats => stats.length > 0);
                
                if (currentYearStats.length > 0) {
                    // Fusionner les statistiques de tous les mois de l'année en cours
                    const mergedCurrentStats = currentYearStats.reduce((acc, monthStats) => {
                        if (acc.length === 0) return monthStats;
                        
                        return acc.map(stat => {
                            const monthStat = monthStats.find(ms => {
                                if (viewType === 'users') {
                                    return ms.id === stat.id;
                                } else {
                                    return ms.location === stat.location;
                                }
                            });
                            
                            if (!monthStat) return stat;

                            return {
                                ...stat,
                                postsCount: stat.postsCount + monthStat.postsCount,
                                totalViews: stat.totalViews + monthStat.totalViews,
                                score: stat.score + monthStat.score,
                                dividedScore: stat.dividedScore + monthStat.dividedScore,
                                // Garder les valeurs les plus récentes pour ces champs
                                subscribers: Math.max(stat.subscribers, monthStat.subscribers),
                                rank: monthStat.rank,
                                rankFromDividedScore: monthStat.rankFromDividedScore,
                                // Conserver les valeurs pour le score de ventes
                                firstMessagesSent: monthStat.firstMessagesSent || stat.firstMessagesSent || 0,
                                potentialContacts: monthStat.potentialContacts || stat.potentialContacts || 0,
                                scoreSales: monthStat.scoreSales || stat.scoreSales || 0
                            };
                        });
                    }, []);

                    // Fusionner les statistiques de tous les mois de l'année précédente
                    const mergedPreviousStats = previousYearStats.length > 0 ? previousYearStats.reduce((acc, monthStats) => {
                        if (acc.length === 0) return monthStats;
                        
                        return acc.map(stat => {
                            const monthStat = monthStats.find(ms => {
                                if (viewType === 'users') {
                                    return ms.id === stat.id;
                                } else {
                                    return ms.location === stat.location;
                                }
                            });
                            
                            if (!monthStat) return stat;

                            return {
                                ...stat,
                                subscribers: Math.max(stat.subscribers, monthStat.subscribers)
                            };
                        });
                    }, []) : [];

                    setStats(mergedCurrentStats);
                    
                    // Si nous sommes en vue par utilisateur, récupérer les connexions et les likers pour chaque utilisateur
                    if (viewType === 'users') {
                        await Promise.all([
                            fetchConnectionsForUsers(mergedCurrentStats),
                            fetchLikersForUsers(mergedCurrentStats)
                        ]);
                    }
                    
                    if (mergedCurrentStats.length > 0 && viewType === 'users') {
                        const entities = [...new Set(mergedCurrentStats.map(stat => stat.location))];
                        setEntities(['All', ...entities]);
                    }
                }
            } else {
                // Si un mois spécifique est sélectionné
                const response = await axios.get(`${apiUrl}/api/users-stats`, {
                    params: {
                        month: selectedMonth,
                        year: selectedYear,
                        entity: selectedEntity,
                        viewType: viewType
                    },
                    withCredentials: true
                });
                setStats(response.data.stats);
                
                // Si nous sommes en vue par utilisateur, récupérer les connexions et les likers pour chaque utilisateur
                if (viewType === 'users') {
                    await Promise.all([
                        fetchConnectionsForUsers(response.data.stats),
                        fetchLikersForUsers(response.data.stats)
                    ]);
                }
                
                if (response.data.entities) {
                    setEntities(response.data.entities);
                }
            }
        } catch (error) {
            console.error('Error fetching stats:', error);
        } finally {
            setLoading(false);
        }
    };

    // Fonction pour récupérer les connexions pour chaque utilisateur
    const fetchConnectionsForUsers = async (users) => {
        try {
            // Créer un tableau de promesses pour récupérer les connexions de chaque utilisateur
            const promises = users.map(user => 
                axios.get(`${apiUrl}/api/sales/connections`, {
                    params: { userId: user.id },
                    withCredentials: true
                })
            );
            
            // Attendre que toutes les promesses soient résolues
            const responses = await Promise.all(promises);
            
            // Créer un objet avec les connexions de chaque utilisateur
            const connections = {};
            responses.forEach((response, index) => {
                console.log(`Response for user ${users[index].id} connections:`, response.data);
                if (response.data.success) {
                    connections[users[index].id] = response.data.connections;
                }
            });
            
            console.log('Final connections object:', connections);
            // Mettre à jour l'état avec les connexions
            setUserConnections(connections);
        } catch (error) {
            console.error('Error fetching connections for users:', error);
        }
    };

    // Fonction pour récupérer les likers pour chaque utilisateur
    const fetchLikersForUsers = async (users) => {
        try {
            // Créer un tableau de promesses pour récupérer les likers de chaque utilisateur
            const promises = users.map(user => 
                axios.get(`${apiUrl}/api/sales/likers`, {
                    params: { userId: user.id },
                    withCredentials: true
                })
            );
            
            // Attendre que toutes les promesses soient résolues
            const responses = await Promise.all(promises);
            
            // Créer un objet avec les likers de chaque utilisateur
            const likers = {};
            responses.forEach((response, index) => {
                console.log(`Response for user ${users[index].id}:`, response.data);
                // La réponse est directement un tableau de likers
                if (Array.isArray(response.data)) {
                    likers[users[index].id] = response.data;
                }
            });
            
            console.log('Final likers object:', likers);
            // Mettre à jour l'état avec les likers
            setUserLikers(likers);
        } catch (error) {
            console.error('Error fetching likers for users:', error);
        }
    };

    // Fonction pour récupérer les mots-clés
    const fetchKeywords = async () => {
        try {
            const response = await fetch(`${apiUrl}/api/settings/keywords`, {
                credentials: 'include'
            });
            if (!response.ok) {
                console.error('Failed to fetch keywords, showing all users');
                setKeywords([]);
                return;
            }
            const data = await response.json();
            setKeywords(data.keywords || []);
        } catch (error) {
            console.error('Error fetching keywords:', error);
            setKeywords([]);
        }
    };

    // Fonction pour filtrer les likers selon les mêmes règles que Sales.jsx
    const getFilteredLikers = (userId) => {
        const likers = userLikers[userId] || [];
        
        // D'abord filtrer les profils Eskimoz et flocons
        const filteredByExcludedTerms = likers.filter(liker => {
            if (!liker.job) return true;
            const jobTitle = liker.job.toLowerCase();
            return !jobTitle.includes('eskimoz') && 
                   !jobTitle.includes('❄️') && 
                   !jobTitle.includes('❄');
        });
        
        // Si pas de mots-clés, retourner tous les profils (sauf ceux exclus)
        if (!keywords || !keywords.length) return filteredByExcludedTerms;
        
        // Appliquer le filtre par mots-clés sur les profils restants
        return filteredByExcludedTerms.filter(liker => {
            if (!liker.job) return false;
            
            const jobTitle = liker.job.toLowerCase();
            // Vérifier si un des mots-clés correspond au titre
            return keywords.some(keyword => {
                const keywordRegex = new RegExp(`\\b${keyword.toLowerCase()}\\b`);
                return keywordRegex.test(jobTitle);
            });
        });
    };

    // Fonction de tri commune pour le tableau et l'export Excel
    const sortData = (data, sortBy, sortOrder) => {
        return [...data].sort((a, b) => {
            let aValue, bValue;

            // Gestion spéciale pour certaines colonnes
            switch (sortBy) {
                case 'name':
                    aValue = viewType === 'users' ? `${a.givenName} ${a.familyName}` : a.location;
                    bValue = viewType === 'users' ? `${b.givenName} ${b.familyName}` : b.location;
                    return sortOrder === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
                
                case 'avgViews':
                    aValue = a.postsCount ? Math.round(a.totalViews / a.postsCount) : 0;
                    bValue = b.postsCount ? Math.round(b.totalViews / b.postsCount) : 0;
                    break;

                case 'impressionsPerUser':
                    aValue = a.userCount ? Math.round(a.totalViews / a.userCount) : 0;
                    bValue = b.userCount ? Math.round(b.totalViews / b.userCount) : 0;
                    break;

                case 'scorePerUser':
                    aValue = a.userCount ? Math.round(a.score / a.userCount) : 0;
                    bValue = b.userCount ? Math.round(b.score / b.userCount) : 0;
                    break;

                case 'scoreSales':
                    // Calculer le ratio pour le tri
                    if (viewType === 'users') {
                        // Récupérer les connexions et les likers
                        const aConnections = userConnections[a.id] || [];
                        const bConnections = userConnections[b.id] || [];
                        const aLikers = getFilteredLikers(a.id) || [];
                        const bLikers = getFilteredLikers(b.id) || [];
                        
                        // Calculer le nombre de messages envoyés
                        const aMessagesCount = aConnections.filter(conn => 
                            conn.messages && conn.messages.length > 0
                        ).length;
                        const bMessagesCount = bConnections.filter(conn => 
                            conn.messages && conn.messages.length > 0
                        ).length;
                        
                        // Calculer le ratio messages/likers
                        aValue = aLikers.length > 0 ? aMessagesCount / aLikers.length : 0;
                        bValue = bLikers.length > 0 ? bMessagesCount / bLikers.length : 0;
                    } else {
                        aValue = a.scoreSales || 0;
                        bValue = b.scoreSales || 0;
                    }
                    break;

                case 'posts':
                    aValue = a.postsCount || 0;
                    bValue = b.postsCount || 0;
                    break;

                case 'views':
                    aValue = a.totalViews || 0;
                    bValue = b.totalViews || 0;
                    break;

                case 'subscribers':
                    aValue = a.subscribers || 0;
                    bValue = b.subscribers || 0;
                    break;

                case 'subscribersWon':
                    aValue = a.subscribersWon || 0;
                    bValue = b.subscribersWon || 0;
                    break;

                default:
                    aValue = a[sortBy] || 0;
                    bValue = b[sortBy] || 0;
            }

            return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
        });
    };

    const handleSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedStats = sortData(stats, orderBy, order);

    const handleViewTypeChange = (event, newViewType) => {
        if (newViewType !== null) {
            setViewType(newViewType);
        }
    };

    const renderTableHeaders = () => {
        if (viewType === 'entities') {
            return (
                <TableRow>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'entity'}
                            direction={orderBy === 'entity' ? order : 'asc'}
                            onClick={() => handleSort('entity')}
                        >
                            Entity
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'userCount'}
                            direction={orderBy === 'userCount' ? order : 'asc'}
                            onClick={() => handleSort('userCount')}
                        >
                            Users
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'posts'}
                            direction={orderBy === 'posts' ? order : 'asc'}
                            onClick={() => handleSort('posts')}
                        >
                            Total Posts
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'views'}
                            direction={orderBy === 'views' ? order : 'asc'}
                            onClick={() => handleSort('views')}
                        >
                            Total Impressions
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'score'}
                            direction={orderBy === 'score' ? order : 'asc'}
                            onClick={() => handleSort('score')}
                        >
                            Total Score
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'impressionsPerUser'}
                            direction={orderBy === 'impressionsPerUser' ? order : 'asc'}
                            onClick={() => handleSort('impressionsPerUser')}
                        >
                            Moy. Impressions/User
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'scorePerUser'}
                            direction={orderBy === 'scorePerUser' ? order : 'asc'}
                            onClick={() => handleSort('scorePerUser')}
                        >
                            Moy. Score/User
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'scoreSales'}
                            direction={orderBy === 'scoreSales' ? order : 'asc'}
                            onClick={() => handleSort('scoreSales')}
                        >
                            Score Sales
                        </TableSortLabel>
                    </TableCell>
                </TableRow>
            );
        }

        return (
            <TableRow>
                <TableCell>
                    <TableSortLabel
                        active={orderBy === 'name'}
                        direction={orderBy === 'name' ? order : 'asc'}
                        onClick={() => handleSort('name')}
                    >
                        Name
                    </TableSortLabel>
                </TableCell>
                <TableCell className='text-center'>Profile</TableCell>
                <TableCell className='text-center'>Connected</TableCell>
                <TableCell>
                    <TableSortLabel
                        active={orderBy === 'job'}
                        direction={orderBy === 'job' ? order : 'asc'}
                        onClick={() => handleSort('job')}
                    >
                        Job
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={orderBy === 'entity'}
                        direction={orderBy === 'entity' ? order : 'asc'}
                        onClick={() => handleSort('entity')}
                    >
                        Entity
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={orderBy === 'subscribers'}
                        direction={orderBy === 'subscribers' ? order : 'asc'}
                        onClick={() => handleSort('subscribers')}
                    >
                        Followers
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={orderBy === 'subscribersWon'}
                        direction={orderBy === 'subscribersWon' ? order : 'asc'}
                        onClick={() => handleSort('subscribersWon')}
                    >
                        Followers Won
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={orderBy === 'posts'}
                        direction={orderBy === 'posts' ? order : 'asc'}
                        onClick={() => handleSort('posts')}
                    >
                        Posts
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={orderBy === 'views'}
                        direction={orderBy === 'views' ? order : 'asc'}
                        onClick={() => handleSort('views')}
                    >
                        Impressions
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={orderBy === 'avgViews'}
                        direction={orderBy === 'avgViews' ? order : 'asc'}
                        onClick={() => handleSort('avgViews')}
                    >
                        Moy. Impressions/Post
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={orderBy === 'score'}
                        direction={orderBy === 'score' ? order : 'asc'}
                        onClick={() => handleSort('score')}
                    >
                        Score
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={orderBy === 'scoreSales'}
                        direction={orderBy === 'scoreSales' ? order : 'asc'}
                        onClick={() => handleSort('scoreSales')}
                    >
                        Score Sales
                    </TableSortLabel>
                </TableCell>
            </TableRow>
        );
    };

    const renderTableRows = () => {
        // Fonction pour formater les nombres avec des espaces entre les milliers
        const formatNumber = (number) => {
            return new Intl.NumberFormat('fr-FR').format(number);
        };

        if (viewType === 'entities') {
            return sortedStats.map((entity) => (
                <TableRow
                    key={entity.location}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {entity.location}
                            {entity.flag && (
                                entity.flag.length === 2 ? (
                                    <ReactCountryFlag
                                        countryCode={entity.flag}
                                        svg
                                        style={{
                                            width: '1.2em',
                                            height: '0.9em',
                                            marginLeft: '4px',
                                            border: '0.5px solid #e0e0e0',
                                            borderRadius: '1px'
                                        }}
                                        title={entity.flag}
                                    />
                                ) : (
                                    <span style={{ fontSize: '0.9em', marginLeft: '4px' }}>{entity.flag}</span>
                                )
                            )}
                        </div>
                    </TableCell>
                    <TableCell>{entity.userCount}</TableCell>
                    <TableCell>{entity.postsCount}</TableCell>
                    <TableCell>{formatNumber(entity.totalViews)}</TableCell>
                    <TableCell>{formatNumber(entity.score || 0)}</TableCell>
                    <TableCell>{entity.userCount > 0 ? formatNumber(Math.round(entity.totalViews / entity.userCount)) : 0}</TableCell>
                    <TableCell>{entity.userCount > 0 ? formatNumber(Math.round(entity.score / entity.userCount)) : 0}</TableCell>
                    <TableCell>{formatNumber(entity.scoreSales || 0)}</TableCell>
                </TableRow>
            ));
        }

        return sortedStats.map((user) => (
            <TableRow
                key={user.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell component="th" scope="row">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {`${user.givenName} ${user.familyName}`}
                        {user.flag && (
                            user.flag.length === 2 ? (
                                <ReactCountryFlag
                                    countryCode={user.flag}
                                    svg
                                    style={{
                                        width: '1.2em',
                                        height: '0.9em',
                                        marginLeft: '4px',
                                        border: '0.5px solid #e0e0e0',
                                        borderRadius: '1px'
                                    }}
                                    title={user.flag}
                                />
                            ) : (
                                <span style={{ fontSize: '0.9em', marginLeft: '4px' }}>{user.flag}</span>
                            )
                        )}
                    </div>
                </TableCell>
                
                <TableCell>
                    {user.lkdUrl ? (
                        <a href={user.lkdUrl} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center',justifyContent: 'center' }}>
                            <img src={linkedinIcon} alt="LinkedIn" style={{ width: '16px', height: '16px', }} />
                        </a>
                    ) : '-'}
                </TableCell>
                <TableCell>
                    <div 
                        style={{ 
                            width: '12px', 
                            height: '12px', 
                            borderRadius: '50%', 
                            backgroundColor: user.isActive ? '#4CAF50' : '#f44336',
                            margin: 'auto',
                        }} 
                        title={user.isActive ? 'Connected' : 'Disconnected'}
                    />
                </TableCell>
                <TableCell>{user.job || '-'}</TableCell>
                <TableCell>{user.location || '-'}</TableCell>
                <TableCell>{formatNumber(user.subscribers || 0)}</TableCell>
                <TableCell>{user.subscribers > 0 ? `+${formatNumber(user.subscribersWon || 0)}` : user.subscribersWon || 0}</TableCell>
                <TableCell>{user.postsCount || 0}</TableCell>
                <TableCell>{formatNumber(user.totalViews || 0)}</TableCell>
                <TableCell>{user.postsCount ? formatNumber(Math.round(user.totalViews / user.postsCount)) : 0}</TableCell>
                <TableCell>{formatNumber(user.score || 0)}</TableCell>
                <TableCell>{formatSalesScore(user.id)}</TableCell>
            </TableRow>
        ));
    };

    const handleExportExcel = () => {
        // Utiliser la même fonction de tri que le tableau
        const sortedStats = sortData(stats, orderBy, order);

        // Préparer les données selon le type d'affichage
        const data = sortedStats.map(stat => {
            if (viewType === 'users') {
                return {
                    'Name': `${stat.givenName} ${stat.familyName}`,
                    'Entity': stat.location || '-',
                    'Job': stat.job || '-',
                    'Posts': stat.postsCount || 0,
                    'Views': stat.totalViews || 0,
                    'Views/Post': stat.postsCount ? Math.round(stat.totalViews / stat.postsCount) : 0,
                    'Score': stat.dividedScore || 0,
                    'Followers': stat.subscribers || 0,
                    'Followers Won': stat.subscribersWon > 0 ? `+${stat.subscribersWon}` : stat.subscribersWon || 0,
                    'Score Sales': formatSalesScore(stat.id)
                };
            } else {
                return {
                    'Entity': stat.location,
                    'Users': stat.userCount || 0,
                    'Posts': stat.postsCount || 0,
                    'Total Score': stat.score || 0,
                    'Moy. Impressions/User': stat.averageViews || 0,
                    'Moy. Score/User': stat.averageScore || 0,
                    'Score Sales': stat.scoreSales || 0
                };
            }
        });

        // Créer et configurer le workbook
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(data);

        // Définir la largeur des colonnes
        const columnWidths = [];
        if (viewType === 'users') {
            columnWidths.push(
                { wch: 25 },  // Name
                { wch: 15 },  // Entity
                { wch: 20 },  // Job
                { wch: 10 },  // Posts
                { wch: 10 },  // Views
                { wch: 12 },  // Views/Post
                { wch: 10 },  // Score
                { wch: 12 },  // Divided Score
                { wch: 8 },   // Rank
                { wch: 18 },  // Rank (Divided Score)
                { wch: 12 },  // Followers
                { wch: 12 },  // Followers Won
                { wch: 8 },    // Active
                { wch: 12 }    // Score Sales
            );
        } else {
            columnWidths.push(
                { wch: 20 },  // Entity
                { wch: 12 },  // Users Count
                { wch: 12 },  // Total Posts
                { wch: 18 },  // Average Posts/User
                { wch: 12 },  // Total Views
                { wch: 18 },  // Average Views/User
                { wch: 12 },  // Total Score
                { wch: 18 },  // Average Score/User
                { wch: 15 },  // Total Followers
                { wch: 20 },  // Average Followers/User
                { wch: 18 },  // Total Followers Won
                { wch: 22 },   // Average Followers Won/User
                { wch: 12 }    // Score Sales
            );
        }
        ws['!cols'] = columnWidths;

        // Ajouter la feuille au workbook et l'exporter
        XLSX.utils.book_append_sheet(wb, ws, "Stats");
        const periodLabel = selectedMonth === 0 ? 'all' : selectedMonth;
        const fileName = `stats_${selectedYear}_${periodLabel}_${viewType}.xlsx`;
        XLSX.writeFile(wb, fileName);
    };

    // Fonction pour formater le score de ventes
    const formatSalesScore = (userId) => {
        console.log(`Formatting sales score for user ${userId}`);
        console.log('Connections:', userConnections[userId]);
        console.log('Likers:', userLikers[userId]);
        
        // Si nous avons des connexions et des likers pour cet utilisateur
        if (userConnections[userId] && userLikers[userId]) {
            const connections = userConnections[userId];
            const filteredLikers = getFilteredLikers(userId);
            const totalLikers = filteredLikers.length;
            const messagesCount = connections.filter(conn => 
                conn.messages && conn.messages.length > 0
            ).length;
            
            console.log(`User ${userId}: Messages=${messagesCount}, Likers=${totalLikers}`);
            
            return `${messagesCount}/${totalLikers}`;
        }
        
        // Si nous n'avons pas de données pour cet utilisateur
        console.log(`No data for user ${userId}`);
        return '0/0';
    };

    // Convertir les entités en format pour le dropdown
    const entityOptions = entities.map(entity => ({
        value: entity,
        label: entity === 'All' ? 'All Entities' : entity
    }));

    return (
        <div className="ranking base pt-3" style={{ flexDirection: 'column' }}>
            <h1 className="my-5" style={{marginLeft:"1.1em"}}>Stats</h1>
            <div style={{ padding: "0.5em 3em 0.5em 3em" }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <DropdownDate
                            label="All"
                            options={months}
                            selectedValue={selectedMonth}
                            onSelect={(value) => setSelectedMonth(value)}
                        />
                        <DropdownDate
                            label="Year"
                            options={years}
                            selectedValue={selectedYear}
                            onSelect={(value) => setSelectedYear(value)}
                        />
                        {viewType === 'users' && (
                            <DropdownDate
                                label="Entity"
                                options={entityOptions}
                                selectedValue={selectedEntity}
                                onSelect={(value) => setSelectedEntity(value)}
                            />
                        )}
                    </div>
                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' , height: '37px'}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleExportExcel}
                            style={{ height: '36px', minWidth: '36px', padding: '6px', backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}
                        >
                            <img src={exportExcelIcon} alt="Export Excel" style={{ width: '24px', height: '24px' }} />
                        </Button>
                        <ToggleButtonGroup
                            value={viewType}
                            exclusive
                            style={{ height: '37px' }}
                            onChange={handleViewTypeChange}
                            aria-label="view type"
                        >
                            <ToggleButton value="users" aria-label="users view">
                                Users
                            </ToggleButton>
                            <ToggleButton value="entities" aria-label="entities view">
                                Entities
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </div>

                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                {renderTableHeaders()}
                            </TableHead>
                            <TableBody>
                                {renderTableRows()}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </div>
        </div>
    );
};

export default Stats;
