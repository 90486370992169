import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaTrash } from 'react-icons/fa';
import Select from 'react-select';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Box,
    Paper,
    Typography,
    Button
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const AutoLike = () => {
    const [users, setUsers] = useState([]);
    const [autolikeList, setAutolikeList] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [currentUserId, setCurrentUserId] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchCurrentUser = async () => {
            try {
                const response = await axios.get(`${apiUrl}/api/extensions`, {
                    withCredentials: true
                });
                if (response.data && response.data.id) {
                    setCurrentUserId(response.data.id);
                    fetchAutolikeList(response.data.id);
                }
            } catch (error) {
                console.error('Error fetching current user:', error);
            }
        };

        fetchCurrentUser();
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/autolike/users`);
            const options = response.data.map(user => ({
                value: user.id,
                label: `${user.givenName || ''} ${user.familyName || ''}`.trim(),
                photo: user.photo,
                givenName: user.givenName,
                familyName: user.familyName
            }));
            setUsers(options);
        } catch (error) {
            console.error('Error fetching users:', error);
            setError('Failed to fetch users');
        }
    };

    const fetchAutolikeList = async (userId) => {
        try {
            const response = await axios.get(`${apiUrl}/api/autolike/${userId}`);
            setAutolikeList(response.data);
        } catch (error) {
            console.error('Error fetching autolike list:', error);
            setError('Failed to load autolike list');
        }
    };

    const addToAutolike = async () => {
        if (!selectedUser) return;

        try {
            await axios.post(`${apiUrl}/api/autolike`, {
                id: selectedUser.value
            });
            fetchAutolikeList(currentUserId);
            setSelectedUser(null);
        } catch (error) {
            console.error('Error adding to autolike:', error);
            setError(error.response?.data?.message || 'Failed to add user to autolike list');
        }
    };

    const removeFromAutolike = async (id) => {
        try {
            await axios.delete(`${apiUrl}/api/autolike/${id}`);
            fetchAutolikeList(currentUserId);
        } catch (error) {
            console.error('Error removing from autolike:', error);
            setError('Failed to remove user from autolike list');
        }
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            borderRadius: '8px',
            border: '1px solid #e0e0e0',
            boxShadow: 'none',
            '&:hover': {
                border: '1px solid #0a66c2'
            },
            minHeight: '50px'
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#0a66c2' : state.isFocused ? '#f5f5f5' : 'white',
            color: state.isSelected ? 'white' : '#333',
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            padding: '12px',
            cursor: 'pointer'
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '8px',
            boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)'
        })
    };

    const formatOptionLabel = ({ label, photo }) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            {photo ? (
                <img src={photo} alt="" style={{ width: 32, height: 32, borderRadius: '50%', objectFit: 'cover' }} />
            ) : (
                <PersonIcon sx={{ width: 32, height: 32, color: '#b0b0b0', p: 0.5, borderRadius: '50%', backgroundColor: '#f5f5f5' }} />
            )}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ fontWeight: 500 }}>{label}</span>
            </div>
        </div>
    );

    return (
        <Box sx={{ p: 3 }}>
            <Paper 
                elevation={2} 
                sx={{ 
                    p: 3,
                    borderRadius: 2,
                    backgroundColor: '#fff'
                }}
            >
                <Typography variant="h5" sx={{ mb: 3, color: '#1a1a1a', fontWeight: 600 }}>
                    AutoLike Settings
                </Typography>
                
                <Box sx={{ 
                    display: 'flex', 
                    gap: 2, 
                    mb: 3,
                    alignItems: 'center'
                }}>
                    <Box sx={{ flex: 1 }}>
                        <Select
                            value={selectedUser}
                            onChange={setSelectedUser}
                            options={users}
                            placeholder="Select a user to add..."
                            isClearable
                            styles={customStyles}
                            formatOptionLabel={formatOptionLabel}
                        />
                    </Box>
                    <Button
                        variant="contained"
                        onClick={addToAutolike}
                        disabled={!selectedUser}
                        sx={{
                            height: '50px',
                            backgroundColor: '#0a66c2',
                            '&:hover': {
                                backgroundColor: '#0a66c2',
                                opacity: 0.9
                            },
                            '&:disabled': {
                                backgroundColor: '#e0e0e0'
                            }
                        }}
                    >
                        Add to AutoLike
                    </Button>
                </Box>

                {error && (
                    <Box sx={{
                        color: '#e11d48',
                        mb: 2,
                        p: 2,
                        backgroundColor: '#fef2f2',
                        borderRadius: 1,
                        border: '1px solid #fecdd3'
                    }}>
                        {error}
                    </Box>
                )}

                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 600, color: '#666' }}>User</TableCell>
                            <TableCell align="right" sx={{ fontWeight: 600, color: '#666' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {autolikeList.map(item => (
                            <TableRow 
                                key={item._id}
                                sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }}
                            >
                                <TableCell sx={{ 
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2
                                }}>
                                    {item.user?.photo ? (
                                        <img 
                                            src={item.user.photo} 
                                            alt="" 
                                            style={{ 
                                                width: 40, 
                                                height: 40, 
                                                borderRadius: '50%',
                                                objectFit: 'cover'
                                            }} 
                                        />
                                    ) : (
                                        <PersonIcon sx={{ 
                                            width: 40, 
                                            height: 40, 
                                            color: '#b0b0b0',
                                            p: 0.5,
                                            borderRadius: '50%',
                                            backgroundColor: '#f5f5f5'
                                        }} />
                                    )}
                                    <span style={{ fontWeight: 500 }}>
                                        {item.user?.givenName} {item.user?.familyName}
                                    </span>
                                </TableCell>
                                <TableCell align="right">
                                    <Button
                                        onClick={() => removeFromAutolike(item.user.id)}
                                        sx={{
                                            minWidth: 'auto',
                                            color: '#e11d48',
                                            '&:hover': {
                                                backgroundColor: '#fee2e2'
                                            }
                                        }}
                                    >
                                        <FaTrash />
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                        {autolikeList.length === 0 && (
                            <TableRow>
                                <TableCell 
                                    colSpan={2} 
                                    sx={{ 
                                        textAlign: 'center', 
                                        color: '#666',
                                        py: 4
                                    }}
                                >
                                    No users in AutoLike list
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Paper>
        </Box>
    );
};

export default AutoLike;