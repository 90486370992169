import React, { useState, useEffect } from 'react';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_BACKEND_URL;

const Popup = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [userId, setUserId] = useState(null);
    const [verificationState, setVerificationState] = useState(null);
    const [verificationCode, setVerificationCode] = useState('');
    const [botCheckInterval, setBotCheckInterval] = useState(null);

    useEffect(() => {
        checkLinkedInCredentials();
        return () => {
            if (botCheckInterval) {
                clearInterval(botCheckInterval);
            }
        };
    }, []);

    const checkLinkedInCredentials = async () => {
        try {
            const userResponse = await axios.get(`${apiUrl}/api/extensions`, {
                withCredentials: true
            });

            if (!userResponse.data || !userResponse.data.id) {
                console.error('No user ID found');
                setShowPopup(true);
                return;
            }

            setUserId(userResponse.data.id);

            const linkedInResponse = await axios.get(`${apiUrl}/api/linkedin/check-credentials/${userResponse.data.id}`, {
                withCredentials: true
            });

            const { hasCredentials, isValid } = linkedInResponse.data;

            if (!hasCredentials || !isValid) {
                setShowPopup(true);
            }
        } catch (error) {
            console.error('Error checking LinkedIn credentials:', error);
            setShowPopup(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setIsSubmitting(true);

        try {
            // Stop any existing bot instance first
            try {
                await axios.post(`${apiUrl}/api/linkedin/stop-bot`, {
                    userId
                });
            } catch (error) {
                console.log('Error stopping bot:', error);
                // Continue with login attempt even if stop fails
            }

            const response = await axios.post(`${apiUrl}/api/linkedin/login`, {
                userId,
                email,
                password
            });

            if (response.data.success) {
                setShowPopup(false);
            } else if (response.data.message === 'Please check your email for verification code') {
                setVerificationState('email');
                setError(null);
            } else if (response.data.message === 'Please verify through LinkedIn mobile app') {
                setVerificationState('app');
                setError(null);
            } else if (response.data.message === 'Authenticator verification required') {
                setVerificationState('authenticator');
                setError(null);
            } else {
                setError(response.data.message || 'Verification failed');
                setVerificationState(null);
            }
        } catch (error) {
            if (error.response?.data?.message === 'Email verification required') {
                setVerificationState('email');
                setError(null);
            } else if (error.response?.data?.message === 'Mobile app verification required') {
                setVerificationState('app');
                setError(null);
            } else if (error.response?.data?.message === 'Authenticator verification required') {
                setVerificationState('authenticator');
                setError(null);
            } else {
                setError('Connection error. Please try again.');
                console.error('Login error:', error);
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const submitVerificationCode = async () => {
        try {
            setError('');
            setIsSubmitting(true);
            
            const response = await axios.post(`${apiUrl}/api/linkedin/submit-verification`, {
                userId,
                code: verificationCode
            });

            if (response.data.success) {
                setShowPopup(false);
            } else {
                setError(response.data.message || 'Invalid verification code');
            }
        } catch (error) {
            if (error.response?.data?.message) {
                setError(error.response.data.message);
            } else {
                setError('Failed to verify code. Please try again.');
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const confirmAppVerification = async () => {
        try {
            setIsSubmitting(true);
            setError('');
            
            const response = await axios.post(`${apiUrl}/api/linkedin/check-app-verification`, {
                userId
            });

            if (response.data.success) {
                setShowPopup(false);
            } else {
                setError(response.data.message || 'Verification failed');
            }
        } catch (error) {
            setError('Failed to confirm verification. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const renderVerificationForm = () => {
        if (verificationState === 'email') {
            return (
                <div className="verification-message">
                    <h3>Email Verification Required</h3>
                    <p>Please check your email for the verification code</p>
                    <div className="form-group">
                        <input
                            type="text"
                            id="verificationCode"
                            value={verificationCode}
                            onChange={(e) => {
                                setVerificationCode(e.target.value);
                                setError(''); // Clear error when user types
                            }}
                            placeholder="Enter verification code"
                            className={`verification-input ${error ? 'error' : ''}`}
                            required
                        />
                        <button 
                            onClick={submitVerificationCode}
                            className="submit-button"
                            disabled={isSubmitting || !verificationCode}
                        >
                            {isSubmitting ? 'Verifying...' : 'Submit Code'}
                        </button>
                        {error && <p className="error-message">{error}</p>}
                    </div>
                </div>
            );
        } else if (verificationState === 'authenticator') {
            return (
                <div className="verification-message">
                    <h3>Authenticator Verification Required</h3>
                    <p>Please enter the code from your authenticator app</p>
                    <div className="form-group">
                        <input
                            type="text"
                            id="verificationCode"
                            value={verificationCode}
                            onChange={(e) => {
                                setVerificationCode(e.target.value);
                                setError(''); // Clear error when user types
                            }}
                            placeholder="Enter authenticator code"
                            className={`verification-input ${error ? 'error' : ''}`}
                            required
                        />
                        <button 
                            onClick={submitVerificationCode}
                            className="submit-button"
                            disabled={isSubmitting || !verificationCode}
                        >
                            {isSubmitting ? 'Verifying...' : 'Submit Code'}
                        </button>
                        {error && <p className="error-message">{error}</p>}
                    </div>
                </div>
            );
        } else if (verificationState === 'app') {
            return (
                <div className="verification-message">
                    <h3>Mobile App Verification Required</h3>
                    <p className="verification-instructions">
                        Please follow these steps:
                        <ol>
                            <li>Open your LinkedIn mobile app</li>
                            <li>Check for a new notification</li>
                            <li>Approve the login request</li>
                        </ol>
                    </p>
                    <button 
                        onClick={confirmAppVerification}
                        className="submit-button"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Verifying...' : 'I\'ve Approved in the App'}
                    </button>
                    {error && <p className="error-message">{error}</p>}
                </div>
            );
        }

        return (
            <form onSubmit={handleSubmit} className="login-form">
                <div className="form-group">
                    <label htmlFor="email">LinkedIn Email</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="your@email.com"
                        required
                        title="Please enter your LinkedIn email address"
                        onInvalid={e => e.target.setCustomValidity('Please enter your LinkedIn email address')}
                        onInput={e => e.target.setCustomValidity('')}
                    />
                </div>
                
                <div className="form-group">
                    <label htmlFor="password">LinkedIn Password</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Your password"
                        required
                        title="Please enter your LinkedIn password"
                        onInvalid={e => e.target.setCustomValidity('Please enter your LinkedIn password')}
                        onInput={e => e.target.setCustomValidity('')}
                    />
                </div>
                
                <button 
                    type="submit" 
                    className={`submit-button ${isSubmitting ? 'submitting' : ''}`}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? (
                        <span className="button-content">
                            <span className="spinner"></span>
                            Verifying...
                        </span>
                    ) : (
                        <span className="button-content">Sign In</span>
                    )}
                </button>
            </form>
        );
    };

    return (
        <div>
            {!isLoading && showPopup && (
                <div className="popup">
                    <div className="popup-content">
                        <h2>LinkedIn Login Required</h2>
                        <p className="description">
                            To analyze your LinkedIn statistics and optimize your activity, 
                            we need your login credentials. Your information is secure and 
                            is only used to access your LinkedIn data.
                        </p>
                        
                        {renderVerificationForm()}

                        {/* N'afficher l'erreur globale que si nous ne sommes pas en mode vérification */}
                        {error && !verificationState && <p className="error-message">{error}</p>}
                    </div>
                </div>
            )}

            <style>{`
                .popup {
                    position: fixed;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.5);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 9999;
                }
                .popup-content {
                    background: white;
                    padding: 30px;
                    border-radius: 8px;
                    width: 400px;
                    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
                }
                .popup h2 {
                    color: #0a66c2;
                    margin-bottom: 15px;
                    font-size: 24px;
                }
                .description {
                    color: #666;
                    margin-bottom: 25px;
                    line-height: 1.5;
                    font-size: 14px;
                }
                .login-form {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                }
                .form-group {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                }
                .popup label {
                    color: #333;
                    font-size: 14px;
                    font-weight: 500;
                }
                .popup input {
                    padding: 10px;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    font-size: 14px;
                }
                .popup input:focus {
                    border-color: #0a66c2;
                    outline: none;
                    box-shadow: 0 0 0 2px rgba(10, 102, 194, 0.1);
                }
                .submit-button {
                    background: #0a66c2;
                    color: white;
                    padding: 12px;
                    border: none;
                    border-radius: 4px;
                    font-size: 14px;
                    font-weight: 600;
                    cursor: pointer;
                    transition: background-color 0.2s;
                }
                .submit-button:hover:not(:disabled) {
                    background: #004182;
                }
                .submit-button:disabled {
                    background: #ccc;
                    cursor: not-allowed;
                }
                .button-content {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;
                }
                .spinner {
                    width: 16px;
                    height: 16px;
                    border: 2px solid #ffffff;
                    border-top: 2px solid transparent;
                    border-radius: 50%;
                    animation: spin 1s linear infinite;
                }
                .verification-message {
                    text-align: center;
                    margin: 20px 0;
                }
                .verification-message h3 {
                    color: #0077b5;
                    margin-bottom: 15px;
                }
                .verification-instructions {
                    margin: 20px 0;
                    text-align: left;
                }
                .verification-instructions ol {
                    margin: 10px 0;
                    padding-left: 20px;
                }
                .verification-instructions li {
                    margin: 5px 0;
                }
                .verification-input {
                    width: 100%;
                    padding: 10px;
                    margin: 10px 0;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    font-size: 16px;
                }
                .verification-input.error {
                    border-color: #e11d48;
                }
                .error-message {
                    color: #e11d48;
                    margin-top: 8px;
                    font-size: 14px;
                }
                @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }
            `}</style>
        </div>
    );
};

export default Popup;
