import React, { useState, useEffect } from 'react';
import './css/library.css';
import { Dropdown } from './selecteur';
import imgExpand from "../img/expand.png";
import { Stack, TextField, MenuItem } from '@mui/material';
import useFileStore from '../store/fileStore';

const Library = () => {
  const [fullscreenFile, setFullscreenFile] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const filesPerPage = 20;
  const apiUrl = process.env.REACT_APP_BACKEND_URL;
  
  const {
    files,
    selectedCategory,
    selectedRealCategory,
    error,
    loading,
    fetchFiles,
    setSelectedCategory,
    setSelectedRealCategory,
    getRealCategories,
    getLanguageCategories,
    resetFilters
  } = useFileStore();

  useEffect(() => {
    if (files.length === 0 && !loading && !error) {
      fetchFiles();
    }
    return () => {
      resetFilters(); // Reset filters when component unmounts
    };
  }, []);

  const handleFileClick = (file, canFullscreen = true) => {
    if (canFullscreen === true) {
      setFullscreenFile(file);
    }
  }
  const handleCloseFullscreen = () => setFullscreenFile(null);

  const truncateUrl = (url) => {
    try {
      const urlObj = new URL(url);
      const path = urlObj.pathname;
      const parts = path.split('/');
      const lastPart = parts[parts.length - (path.endsWith('/') ? 2 : 1)];
      return `...${lastPart}`;
    } catch (err) {
      return url;
    }
  };

  const renderFile = (file, isFullscreen = false) => {
    const originalName = file.metadata.originalName || 'Unknown';
    const filename = file.filename;
    const contentType = file.contentType || 'application/octet-stream';

    if (contentType.startsWith('image/')) {
      const imageUrl = `${apiUrl}/api/image/${encodeURIComponent(filename)}`;
      return (
        <div className="file-image-wrapper">
          <img 
            src={imageUrl} 
            alt={originalName} 
            className="file-image"
            loading="lazy"
          />
          {isFullscreen && (
            <a
              href={`${apiUrl}/api/download/${file._id}?download=true`}
              download={originalName}
              className="download-btn"
              onClick={(e) => e.stopPropagation()}
            >
              Download
            </a>
          )}
        </div>
      );
    } else if (contentType === 'application/pdf') {
      const pdfUrl = `${apiUrl}/api/pdf/${encodeURIComponent(filename)}`;
      
      return (
        <div className="file-pdf">
          <div className="pdf-preview" style={{ cursor: 'pointer', height: '100%' }} onClick={() => handleFileClick(file, true)}>
            <img 
              className="expandPdf" 
              src={imgExpand} 
              alt="expand"
              title="Cliquez pour agrandir"
            />
            <iframe 
              src={pdfUrl + '#toolbar=0'}
              title="PDF Viewer"
              key={`pdf-${file._id}`}
              style={{ 
                width: '100%',
                height: '100%',
                border: 'none',
                overflow: 'auto'
              }}
              frameBorder="0"
            />
          </div>
          {isFullscreen && (
            <a
              href={`${apiUrl}/api/download/${file._id}?download=true`}
              download={originalName}
              className="download-btn"
              onClick={(e) => e.stopPropagation()}
            >
              Download
            </a>
          )}
        </div>
      );
    } else if (contentType.startsWith('video/')) {
      return (
        <div>
          <video crossOrigin="anonymous" maxWidth="100%" height="320px" controls className="file-video">
            <source src={`${apiUrl}/api/video/${encodeURIComponent(filename)}`} type={contentType} />
            Your browser does not support the video tag.
          </video>
          {isFullscreen && (
            <a
              href={`${apiUrl}/api/download/${file._id}?download=true`}
              download={originalName}
              className="download-btn"
              onClick={(e) => e.stopPropagation()}
            >
              Download
            </a>
          )}
        </div>
      );
    } else {
      return <div>Unsupported file type</div>;
    }
  };

  if (error) {
    return <div className="error">{error}</div>;
  }

  const realCategoryOptions = getRealCategories();
  const categoryOptions = getLanguageCategories();

  // Calcul de la pagination
  const indexOfLastFile = currentPage * filesPerPage;
  const indexOfFirstFile = indexOfLastFile - filesPerPage;
  const currentFiles = files.slice(indexOfFirstFile, indexOfLastFile);
  const totalPages = Math.ceil(files.length / filesPerPage);

  // Changement de page
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo(0, 0);
  };

  // Navigation vers la page précédente/suivante
  const handlePrevPage = () => {
    if (currentPage > 1) {
      handlePageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      handlePageChange(currentPage + 1);
    }
  };

  return (
    <div className="base">
      <div className="library">
        <h1>Library</h1>
        <div className="select-tag">
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start">
            <TextField
              select
              label="Language"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              variant="outlined"
              size="small"
              sx={{ minWidth: 200 }}
            >
              {categoryOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            
            <TextField
              select
              label="Category"
              value={selectedRealCategory}
              onChange={(e) => setSelectedRealCategory(e.target.value)}
              variant="outlined"
              size="small"
              sx={{ minWidth: 200 }}
            >
              {realCategoryOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </div>
        <div className="file-grid">
          {loading ? (
            <div>Loading...</div>
          ) : files.length > 0 ? (
            <>
              {currentFiles.map((file) => (
                <div key={file._id} className="file-item" onClick={() => handleFileClick(file, !file.contentType.startsWith('video/'))}>
                  {renderFile(file)}
                  <div className="file-name">{file.metadata.name}</div>
                  <div className="tags">
                    {file.metadata.tag && (
                      <span className="tag">{file.metadata.tag}</span>
                    )}
                  </div>
                  {file.metadata.links && file.metadata.links.length > 0 && (
                    <div className="associated-posts">
                      <>Associated links :</>
                      <div className="post-links">
                        {file.metadata.links.map((link, index) => (
                          <a 
                            key={index}
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="post-link"
                            onClick={(e) => e.stopPropagation()}
                            title={link}
                          >
                            {truncateUrl(link)}
                          </a>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
              {totalPages > 1 && (
                <div className="pagination">
                  <button 
                    className="page-button nav-button" 
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  {Array.from({ length: totalPages }, (_, i) => i + 1).map((number) => (
                    <button
                      key={number}
                      className={`page-button ${currentPage === number ? 'active' : ''}`}
                      onClick={() => handlePageChange(number)}
                    >
                      {number}
                    </button>
                  ))}
                  <button 
                    className="page-button nav-button" 
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>
              )}
            </>
          ) : (
            <div>No files found</div>
          )}
        </div>
      </div>
      {fullscreenFile && (
        <div className="fullscreen" onClick={handleCloseFullscreen}>
          {fullscreenFile.contentType === 'application/pdf' ? (
            <div className="file-pdf" style={{ height: '100%'}}>
              <embed
                src={`${apiUrl}/api/pdf/${encodeURIComponent(fullscreenFile.filename)}`}
                type="application/pdf"
                width="90%"
                height="80%"
                onError={(e) => console.error("Error loading PDF in fullscreen:", e)}
              />
              <div className="pdf-actions">
                <a
                  href={`${apiUrl}/api/download/${fullscreenFile._id}?download=true`}
                  download={fullscreenFile.metadata.originalName || 'File.pdf'}
                  className="download-btn"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  Download
                </a>
              </div>
            </div>
          ) : (
            renderFile(fullscreenFile, true)
          )}
        </div>
      )}
    </div>
  );
};

export default Library;