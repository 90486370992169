import React, { useState, useEffect } from 'react';
import './css/user.css';
import {
    Table, TableBody, TableCell, TableHead, TableRow,
    Dialog, DialogTitle, DialogContent, DialogActions,
    TextField, Button, Select, MenuItem, InputLabel,
    Slider, Typography, Box, CircularProgress
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ReactCountryFlag from 'react-country-flag';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const User = () => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [job, setJob] = useState('');
    const [location, setLocation] = useState('');
    const [flag, setFlag] = useState('');
    const [lkdUrl, setLkdUrl] = useState('');
    const [divideScore, setDivideScore] = useState(1);
    const [role, setRole] = useState('consultant');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [updatePostsLoading, setUpdatePostsLoading] = useState(false);

    // Liste des pays avec leurs codes ISO
    const countries = [
        { code: 'FR', name: 'France' },
        { code: 'GB', name: 'United Kingdom' },
        { code: 'DE', name: 'Germany' },
        { code: 'ES', name: 'Spain' },
        { code: 'IT', name: 'Italy' },
        { code: 'BE', name: 'Belgium' },
        { code: 'CH', name: 'Switzerland' },
        { code: 'NL', name: 'Netherlands' },
        { code: 'PT', name: 'Portugal' },
        { code: 'LU', name: 'Luxembourg' }
    ];

    // Fetch users from the API
    const fetchUsers = async () => {
        try {
            const response = await fetch(`${apiUrl}/api/users-settings-with-scores`);
            const data = await response.json();
            // Trier par currentRank
            const sortedData = data.sort((a, b) => (a.rankFromDividedScore || Infinity) - (b.rankFromDividedScore || Infinity));
            setUsers(sortedData);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    // Fetch users when the component mounts
    useEffect(() => {
        fetchUsers();
    }, []);

    // Handle user selection
    const handleUserClick = (user) => {
        setSelectedUser(user);
        setJob(user.job || '');
        setLocation(user.location || '');
        setFlag(user.flag || '');
        setLkdUrl(user.lkdUrl || '');
        setDivideScore(user.divideScore || 1);
        setRole(user.role || 'consultant');
    };

    // Cancel user selection
    const handleCancel = () => {
        setSelectedUser(null);
    };

    // Add or update user
    const handleAddOrUpdate = async (e) => {
        e.preventDefault();
        const updatedUser = {
            job,
            location,
            flag,
            lkdUrl,
            divideScore,
            role
        };

        try {
            if (selectedUser._id) {
                // Update existing user
                await fetch(`${apiUrl}/api/users-settings/${selectedUser._id}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(updatedUser),
                });
                await fetchUsers(); // Rafraîchir la liste après la mise à jour
            } else {
                // Add a new user
                await fetch(`${apiUrl}/api/users-settings`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(updatedUser),
                });
                await fetchUsers(); // Rafraîchir la liste après l'ajout
            }
            handleCancel();
        } catch (error) {
            console.error('Error adding or updating user:', error);
        }
    };

    const handleUpdateRankings = async () => {
        try {
            setUpdateLoading(true);
            const response = await fetch(`${apiUrl}/api/update-rankings`, {
                method: 'GET',
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error('Failed to update rankings');
            }

            fetchUsers();
        } catch (error) {
            console.error('Error updating rankings:', error);
        } finally {
            setUpdateLoading(false);
        }
    };

    const handleUpdateDailyPosts = async () => {
        try {
            setUpdatePostsLoading(true);
            const response = await fetch(`${apiUrl}/api/fetch-daily-posts`, {
                method: 'GET',
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error('Failed to update daily posts');
            }
            
            fetchUsers();
        } catch (error) {
            console.error('Error updating daily posts:', error);
        } finally {
            setUpdatePostsLoading(false);
        }
    };

    return (
        <div className="box">
            <div className="flex gap-4 mb-4">
                
                <Button
                    type="button"
                    onClick={handleUpdateDailyPosts}
                    disabled={updatePostsLoading}
                    className={`text-white m-2 py-2 px-4 rounded ${updatePostsLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    style={{ backgroundColor: updatePostsLoading ? '#ccc' : '#007BFF' }}
                >
                    {updatePostsLoading ? <CircularProgress size={20} color="inherit" /> : 'Fetch Daily Posts'}
                </Button>
                <Button
                    type="button"
                    onClick={handleUpdateRankings}
                    disabled={updateLoading}
                    className={`text-white m-2 py-2 px-4 rounded ${updateLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    style={{ backgroundColor: updateLoading ? '#ccc' : '#007BFF' }}
                >
                    {updateLoading ? <CircularProgress size={20} color="inherit" /> : 'Update Rankings'}
                </Button>
                
            </div>
            <div className="user">
                <h2>Users</h2>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell className='text-center'>Name</TableCell>
                            <TableCell className='text-center'>Role</TableCell>
                            <TableCell className='text-center'>Job</TableCell>
                            <TableCell className='text-center'>Location</TableCell>
                            <TableCell className='text-center'>Flag</TableCell>
                            <TableCell className='text-center'>Number of Posts</TableCell>
                            <TableCell className='text-center'>Views</TableCell>
                            <TableCell className='text-center'>Divide Score</TableCell>
                            <TableCell className='text-center'>Real Score</TableCell>
                            <TableCell className='text-center'>Divided Score</TableCell>
                            <TableCell className='text-center'>Real Rank</TableCell>
                            <TableCell className='text-center'>Divided Score Rank</TableCell>
                            <TableCell className='text-center'>Daily Progression</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow key={user._id} onClick={() => handleUserClick(user)} style={{ cursor: 'pointer' }}>
                                <TableCell>
                                    {user.photo ? (
                                        <img src={user.photo} alt={user.givenName} style={{ width: 40, height: 40, borderRadius: '50%' }} />
                                    ) : (
                                        <PersonIcon style={{ fontSize: 40, color: '#b0b0b0' }} />
                                    )}
                                </TableCell>
                                <TableCell className='text-center'>{user.givenName} {user.familyName}</TableCell>
                                <TableCell className='text-center' style={{ 
                                    color: user.role === 'admin' ? '#ff1744' : 
                                           user.role === 'manager' ? '#2196f3' : 
                                           user.role === 'normal' ? '#4caf50' : '#757575'
                                }}>
                                    {user.role || 'consultant'}
                                </TableCell>
                                <TableCell className='text-center'>{user.job}</TableCell>
                                <TableCell className='text-center'>{user.location}</TableCell>
                                <TableCell className='text-center'>
                                    {user.flag && (
                                        user.flag.length === 2 ? (
                                            <ReactCountryFlag
                                                countryCode={user.flag}
                                                svg
                                                style={{
                                                    width: '1.2em',
                                                    height: '0.9em',
                                                    border: '0.5px solid #e0e0e0',
                                                    borderRadius: '1px'
                                                }}
                                                title={user.flag}
                                            />
                                        ) : (
                                            <span style={{ fontSize: '0.9em' }}>{user.flag}</span>
                                        )
                                    )}
                                </TableCell>
                                <TableCell className='text-center'>{user.currentPosts || 0}</TableCell>
                                <TableCell className='text-center'>{user.currentViews || 0}</TableCell>
                                <TableCell className='text-center'>{user.divideScore || 1}</TableCell>
                                <TableCell className='text-center'>{user.currentScore || 0}</TableCell>
                                <TableCell className='text-center'>{user.currentDividedScore || 0}</TableCell>
                                <TableCell className='text-center'>{user.currentRank || 0}</TableCell>
                                <TableCell className='text-center'>{user.rankFromDividedScore || '-'}</TableCell>
                                <TableCell className='text-center'>
                                    {user.dailyProgression > 0 ? (
                                        <span style={{ color: 'green' }}>+{user.dailyProgression}</span>
                                    ) : user.dailyProgression < 0 ? (
                                        <span style={{ color: 'red' }}>{user.dailyProgression}</span>
                                    ) : (
                                        <span>=</span>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                <Dialog open={Boolean(selectedUser)} onClose={handleCancel} width={"40em"} fullWidth>
                    <DialogTitle>Update {selectedUser ? `${selectedUser.givenName} ${selectedUser.familyName}` : 'User'}</DialogTitle>
                    <form onSubmit={handleAddOrUpdate}>
                        <DialogContent>
                            <TextField
                                label="Job"
                                value={job}
                                onChange={(e) => setJob(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                            <InputLabel>Location</InputLabel>
                            <Select
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                                fullWidth
                                margin="normal"
                            >
                                <MenuItem value="Eskimoz FR">Eskimoz FR</MenuItem>
                                <MenuItem value="Eskimoz UK">Eskimoz UK</MenuItem>
                                <MenuItem value="Eskimoz IT">Eskimoz IT</MenuItem>
                                <MenuItem value="Eskimoz ES">Eskimoz ES</MenuItem>
                                <MenuItem value="Semtrix">Semtrix</MenuItem>
                                <MenuItem value="Neads">Neads</MenuItem>
                                <MenuItem value="Deeploy">Deeploy</MenuItem>
                                <MenuItem value="Staenk">Staenk</MenuItem>
                                <MenuItem value="Paper Club">Paper Club</MenuItem>
                                <MenuItem value="Senek">Senek</MenuItem>
                                <MenuItem value="Group">Group</MenuItem>
                                <MenuItem value="119">119</MenuItem>
                            </Select>

                            <InputLabel>Flag</InputLabel>
                            <Select
                                value={flag}
                                onChange={(e) => setFlag(e.target.value)}
                                fullWidth
                                style={{ marginBottom: '1rem' }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {countries.map((country) => (
                                    <MenuItem key={country.code} value={country.code}>
                                        <ReactCountryFlag
                                            countryCode={country.code}
                                            svg
                                            style={{
                                                width: '1.2em',
                                                height: '0.9em',
                                                marginRight: '8px',
                                                border: '0.5px solid #e0e0e0',
                                                borderRadius: '1px'
                                            }}
                                        />
                                        {country.name}
                                    </MenuItem>
                                ))}
                            </Select>

                            <TextField
                                fullWidth
                                label="LinkedIn Profile"
                                value={lkdUrl}
                                onChange={(e) => setLkdUrl(e.target.value)}
                                margin="normal"
                                placeholder="LinkedIn URL"
                            />

                            <InputLabel id="role-label" >Role</InputLabel>
                            <Select
                                labelId="role-label"
                                value={role}
                                onChange={(e) => setRole(e.target.value)}
                                fullWidth
                                margin="normal"
                            >
                                <MenuItem value="consultant">Consultant</MenuItem>
                                <MenuItem value="normal">Normal</MenuItem>
                                <MenuItem value="manager">Manager</MenuItem>
                                <MenuItem value="admin">Admin</MenuItem>
                            </Select>

                            <div style={{ margin: '20px 0' }}>
                                <InputLabel>Divide Score</InputLabel>
                                <Slider
                                    value={divideScore}
                                    onChange={(e, newValue) => setDivideScore(newValue)}
                                    min={1}
                                    max={20}
                                    step={.5}
                                    marks
                                    valueLabelDisplay="auto"
                                />
                                {selectedUser?.currentScore ? (
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        mt: 2
                                    }}>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1,
                                            mb: 0.5
                                        }}>
                                            <Typography variant="h6">
                                                {selectedUser.currentScore}
                                            </Typography>
                                            <ArrowForwardIcon />
                                            <Typography variant="h6">
                                                {Math.round(selectedUser.currentScore / divideScore)}
                                            </Typography>
                                        </Box>
                                        <Typography
                                            variant="caption"
                                            color="text.secondary"
                                        >
                                            estimated score
                                        </Typography>
                                    </Box>
                                ) : null}
                            </div>

                            
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCancel} color="primary">
                                Cancel
                            </Button>
                            <Button type="submit" color="primary" variant="contained">
                                Save
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        </div>
    );
};

export default User;
