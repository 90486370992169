// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-container {
    display: flex;
    width: 705px;
    justify-content: space-around;
    margin-left: 60px;
    background-color: #F6F6F6;
    padding: 2px;
    border-radius: 8px;
    box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.05);
    position: relative;
    overflow: hidden;
}

.menu-container .menu-item {
    font-size: 16px;
    color: #333;
    padding: 10px 20px;
    border-radius: 8px;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-weight: 500;
}

.menu-container .menu-item:hover,
.menu-container .menu-item.active {
    background-color: white;
}

.menu-container .menu {
    position: relative;
}

.menu-container .menu-item {
    position: relative;
    font-size: 16px;
    color: #333;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}`, "",{"version":3,"sources":["webpack://./src/pages/settings/css/menu.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,6BAA6B;IAC7B,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,iDAAiD;IACjD,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,uDAAuD;IACvD,gBAAgB;AACpB;;AAEA;;IAEI,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,uDAAuD;AAC3D","sourcesContent":[".menu-container {\n    display: flex;\n    width: 705px;\n    justify-content: space-around;\n    margin-left: 60px;\n    background-color: #F6F6F6;\n    padding: 2px;\n    border-radius: 8px;\n    box-shadow: inset 1px 2px 4px rgba(0, 0, 0, 0.05);\n    position: relative;\n    overflow: hidden;\n}\n\n.menu-container .menu-item {\n    font-size: 16px;\n    color: #333;\n    padding: 10px 20px;\n    border-radius: 8px;\n    transition: background-color 0.3s ease, color 0.3s ease;\n    font-weight: 500;\n}\n\n.menu-container .menu-item:hover,\n.menu-container .menu-item.active {\n    background-color: white;\n}\n\n.menu-container .menu {\n    position: relative;\n}\n\n.menu-container .menu-item {\n    position: relative;\n    font-size: 16px;\n    color: #333;\n    padding: 10px 20px;\n    border-radius: 8px;\n    cursor: pointer;\n    transition: background-color 0.3s ease, color 0.3s ease;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
