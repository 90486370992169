import React, { createContext, useState, useEffect, useContext, useRef, useCallback } from 'react';

const apiUrl = process.env.REACT_APP_BACKEND_URL;
const AuthContext = createContext();

// Variable globale pour suivre l'état du bot entre les rendus et les rechargements
let botStartInitiated = false;

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [linkedinId, setLinkedinId] = useState(null);
    const [isBotRunning, setIsBotRunning] = useState(false);
    const [botLoading, setBotLoading] = useState(false);
    const botTimerRef = useRef(null);
    const authCheckPerformedRef = useRef(false);

    // Fonction pour démarrer le bot LinkedIn
    const startBot = useCallback(async () => {
        // Si le bot est déjà en cours d'exécution ou en chargement, ne rien faire
        if (isBotRunning || botLoading || !linkedinId) return true;
        
        // Éviter les démarrages multiples en utilisant la variable globale
        if (botStartInitiated) {
            console.log('Bot start already initiated globally, skipping');
            return true;
        }
        
        // Marquer le démarrage du bot comme initié
        botStartInitiated = true;
        
        try {
            setBotLoading(true);
            console.log('Starting LinkedIn bot...');
            const response = await fetch(`${apiUrl}/api/linkedin/start`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({ userId: linkedinId })
            });

            const data = await response.json();
            setBotLoading(false);
            
            if (!response.ok) {
                console.error('Error starting bot:', data);
                setIsBotRunning(false);
                return false;
            }

            console.log('Bot started successfully:', data);
            
            // Démarrer le chronomètre pour arrêter le bot après une heure
            if (botTimerRef.current) {
                clearTimeout(botTimerRef.current);
            }
            
            setIsBotRunning(true);
            return true;
        } catch (error) {
            console.error('Error starting bot:', error);
            setBotLoading(false);
            setIsBotRunning(false);
            return false;
        }
    }, [linkedinId, isBotRunning, botLoading]);

    // Fonction pour arrêter le bot LinkedIn
    const stopBot = useCallback(async () => {
        if (!linkedinId) return;
        
        try {
            console.log('Stopping LinkedIn bot...');
            const response = await fetch(`${apiUrl}/api/linkedin/stop-bot`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify({ userId: linkedinId })
            });

            const data = await response.json();
            
            if (!response.ok) {
                console.error('Error stopping bot:', data);
            } else {
                console.log('Bot stopped successfully:', data);
            }
            
            // Nettoyer le timer
            if (botTimerRef.current) {
                clearTimeout(botTimerRef.current);
                botTimerRef.current = null;
            }
            
            setIsBotRunning(false);
            // Réinitialiser la variable globale
            botStartInitiated = false;
        } catch (error) {
            console.error('Error stopping bot:', error);
            setIsBotRunning(false);
            // Réinitialiser la variable globale
            botStartInitiated = false;
        }
    }, [linkedinId]);

    // Nettoyer le timer lorsque le composant est démonté
    useEffect(() => {
        return () => {
            if (botTimerRef.current) {
                clearTimeout(botTimerRef.current);
                stopBot();
            }
        };
    }, [stopBot]);

    useEffect(() => {
        const checkAuthStatus = async () => {
            // Éviter les vérifications multiples
            if (authCheckPerformedRef.current) {
                console.log('Auth check already performed, skipping');
                return;
            }
            
            authCheckPerformedRef.current = true;
            
            try {
                console.log(`request ${apiUrl}/auth/status`);
                const response = await fetch(`${apiUrl}/auth/status`, {
                    method: 'GET',
                    credentials: 'include',
                });

                const data = await response.json();

                if (data.isAuthenticated) {
                    setIsAuthenticated(true);
                    setIsAdmin(data.isAdmin);
                    setLinkedinId(data.linkedinId);
                    
                    await startBot();
                } else {
                    setIsAuthenticated(false);
                    setIsAdmin(false);
                    setLinkedinId(null);
                }
            } catch (error) {
                console.error('Error checking auth status:', error);
                setIsAuthenticated(false);
                setIsAdmin(false);
                setLinkedinId(null);
            }
        };

        checkAuthStatus();
        
        // Nettoyer la référence lors du démontage
        return () => {
            authCheckPerformedRef.current = false;
        };
    }, [startBot]);

    return (
        <AuthContext.Provider value={{ 
            isAuthenticated, 
            isAdmin, 
            linkedinId, 
            isBotRunning, 
            botLoading, 
            startBot, 
            stopBot 
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
