import React, { useState } from 'react';
import './css/menu.css';


const Menu = ({ activeTab, onTabClick, isAdmin }) => {
    const [hoverIndex, setHoverIndex] = useState(-1);  // État pour suivre l'index de l'élément survolé

    // Si l'utilisateur est admin, il a accès à tous les onglets
    // Sinon, il n'a accès qu'à l'onglet Settings
    const menuItems = isAdmin 
        ? ['Settings', 'Feed', 'Users', 'Tips', 'Library', 'Awards', 'AutoLike', 'Sales']
        : ['Settings'];

    return (
        <div className="menu-container">
            <div className="menu-background" style={{ top: `${hoverIndex * 48}px` }} />
            {menuItems.map((item, index) => (
                <div
                    key={item}
                    className={`menu-item ${activeTab === item ? 'active' : ''}`}
                    onClick={() => onTabClick(item)}
                    onMouseEnter={() => setHoverIndex(index)}  // Mettre à jour l'index au survol
                    onMouseLeave={() => setHoverIndex(-1)}  // Réinitialiser l'index quand la souris quitte
                >
                    {item}
                </div>
            ))}
        </div>
    );
};

export default Menu;
