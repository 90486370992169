import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Link,
  Avatar,
  TableSortLabel,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Box,
  Tabs,
  Tab,
  Pagination,
  Snackbar,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import Alert from '@mui/material/Alert';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import linkedinIcon from '../img/icons/linkedinIcon.png';
import EmailIcon from '@mui/icons-material/Email';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useAuth } from '../authContext';
import './css/sales.css';

const Sales = () => {
  const [likers, setLikers] = useState([]);
  const [orderBy, setOrderBy] = useState('lastLikeDate');
  const [order, setOrder] = useState('desc');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [confirmDialog, setConfirmDialog] = useState({ open: false, liker: null });
  const [messageDialog, setMessageDialog] = useState({
    open: false,
    liker: null,
    message: '',
    isConnected: false
  });
  const [confirmMessageDialog, setConfirmMessageDialog] = useState({
    open: false,
    liker: null,
    message: ''
  });
  const [keywords, setKeywords] = useState([]);

  const [connections, setConnections] = useState([]); 
  const [favoriteMessages, setFavoriteMessages] = useState([]);
  const [favoriteMessagesPage, setFavoriteMessagesPage] = useState(1);
  const [totalFavoriteMessages, setTotalFavoriteMessages] = useState(0);
  const [totalFavoritePages, setTotalFavoritePages] = useState(0);
  const [messageType, setMessageType] = useState('recent'); // 'recent' ou 'favorite'
  const [userStats, setUserStats] = useState({ firstMessagesSent: 0, potentialContacts: 0 });
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [botLoading, setBotLoading] = useState(false);
  const { linkedinId, isBotRunning, startBot } = useAuth();
  const apiUrl = process.env.REACT_APP_BACKEND_URL;
  const [excludeKeywords, setExcludeKeywords] = useState([]);
  const [favoriteFilter, setFavoriteFilter] = useState('all');

  const fetchKeywords = useCallback(async () => {
    try {
      const response = await fetch(`${apiUrl}/api/settings/keywords`, {
        credentials: 'include'
      });
      if (!response.ok) {
        console.error('Failed to fetch keywords, showing all users');
        setKeywords([]);
        return;
      }
      const data = await response.json();
      setKeywords(data.keywords || []);
    } catch (error) {
      console.error('Error fetching keywords:', error);
      // In case of error, show all users
      setKeywords([]);
    }
  }, []);
  
  const fetchExcludeKeywords = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/settings/exclude-keywords`, {
        credentials: 'include'
      });
      if (!response.ok) {
        console.error('Failed to fetch exclude keywords');
        setExcludeKeywords([]);
        return;
      }
      const data = await response.json();
      setExcludeKeywords(data.keywords || []);
    } catch (error) {
      console.error('Error fetching exclude keywords:', error);
      setExcludeKeywords([]);
    }
  }, []);

  useEffect(() => {
    fetchKeywords();
    fetchExcludeKeywords();
  }, [fetchKeywords, fetchExcludeKeywords]);

  const fetchLikers = useCallback(async () => {
    try {
      console.log('Fetching likers...');
      const url = `${apiUrl}/api/sales/likers?userId=${linkedinId}`;
      console.log('Request URL:', url);

      const response = await fetch(url);
      console.log('Response status:', response.status);
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Response not OK:', errorText);
        throw new Error('Error while fetching data');
      }

      const data = await response.json();
      setLikers(data);
    } catch (error) {
      console.error('Error fetching likers:', error);
    }
  }, [linkedinId]);

  useEffect(() => {
    if (linkedinId) {
      fetchLikers();
    }
  }, [linkedinId, fetchLikers]);

  const fetchConnections = useCallback(async () => {
    try {
      console.log('Fetching connections...');
      const url = `${apiUrl}/api/sales/connections?userId=${linkedinId}`;
      console.log('Request URL:', url);
      console.log('LinkedinId:', linkedinId);

      const response = await fetch(url);
      console.log('Response status:', response.status);
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Response not OK:', errorText);
        throw new Error('Error while fetching data');
      }

      const data = await response.json();
      console.log('Received connections data:', data);
      
      if (data.success) {
        console.log('Setting connections:', data.connections);
        setConnections(data.connections);
      }
    } catch (error) {
      console.error('Error fetching connections:', error);
    }
  }, [linkedinId]);

  useEffect(() => {
    if (linkedinId) {
      fetchConnections();
    }
  }, [linkedinId, fetchConnections]);

  // Charger les messages favoris
  const loadFavoriteMessages = async (page = 1) => {
    try {
      const response = await fetch(`${apiUrl}/api/sales/favorite-messages/${linkedinId}?page=${page}`, {
        credentials: 'include'
      });
      const data = await response.json();
      if (data.success) {
        setFavoriteMessages(data.messages);
        setTotalFavoriteMessages(data.total);
        setTotalFavoritePages(data.totalPages);
        setFavoriteMessagesPage(data.page);
      }
    } catch (error) {
      console.error('Error loading favorite messages:', error);
    }
  };

  useEffect(() => {
    if (linkedinId) {
      loadFavoriteMessages();
    }
  }, [linkedinId]);

  // Basculer le statut favori d'un message
  const toggleFavorite = async (connexionUrl, messageId) => {
    try {
      console.log('Toggling favorite for message:', messageId, 'in connection:', connexionUrl);
      
      // Convertir l'ID en string pour être sûr
      const messageIdStr = typeof messageId === 'object' ? messageId.toString() : messageId;
      
      const response = await fetch(`${apiUrl}/api/sales/toggle-favorite`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({
          userId: linkedinId,
          connexionUrl,
          messageId: messageIdStr
        })
      });
      
      const data = await response.json();
      console.log('Toggle response:', data);
      
      if (data.success) {
        // Mettre à jour l'état du message dans la liste des messages récents
        setConnections(prevConnections => {
          const newConnections = prevConnections.map(conn => {
            if (conn.connexionUrl === connexionUrl) {
              return {
                ...conn,
                messages: conn.messages.map(msg => {
                  // Convertir l'ID en string pour la comparaison
                  const msgIdStr = typeof msg._id === 'object' ? msg._id.toString() : msg._id;
                  
                  if (msgIdStr === messageIdStr) {
                    console.log('Updating message favorite status to:', data.favorite);
                    return { ...msg, favorite: data.favorite };
                  }
                  return msg;
                })
              };
            }
            return conn;
          });
          
          console.log('Updated connections:', newConnections);
          return newConnections;
        });
        
        // Recharger complètement les messages favoris pour s'assurer qu'ils sont à jour
        loadFavoriteMessages();
      }
    } catch (error) {
      console.error('Error toggling favorite:', error);
    }
  };

  // Récupérer les 10 derniers messages de toutes les connexions
  const getRecentMessages = () => {
    // Extraire tous les messages avec leurs informations
    const allMessages = connections.reduce((messages, connection) => {
      if (!connection.messages) return messages;
      
      // Ajouter les messages de cette connexion
      const connectionMessages = connection.messages.map(msg => {
        // S'assurer que tous les champs nécessaires sont présents
        return {
          content: msg.content,
          sentAt: new Date(msg.sentAt), // Convertir en Date pour le tri
          connexionName: connection.connexionName,
          connexionUrl: connection.connexionUrl,
          favorite: !!msg.favorite, // Convertir en boolean pour être sûr
          _id: msg._id, // Garder _id pour la cohérence
          id: msg._id // Garder aussi id pour la compatibilité
        };
      });
      
      return messages.concat(connectionMessages);
    }, []);

    // Trier par date décroissante et prendre les 10 plus récents
    return allMessages
      .sort((a, b) => b.sentAt - a.sentAt)
      .slice(0, 10)
      .map(msg => ({
        ...msg,
        sentAt: msg.sentAt.toISOString(), // Reconvertir en string pour l'affichage
      }));
  };

  // Filter likers based on keywords and exclude those with 'eskimoz' or snowflake emojis (❄️ and ❄) in their job title
  const filteredLikers = useMemo(() => {
    if (!likers || !Array.isArray(likers)) return [];
    
    // Filtrer par favoris si nécessaire (première étape)
    let filteredResults = [...likers];
    
    if (favoriteFilter === 'favorites') {
      filteredResults = filteredResults.filter(liker => liker.favorite === true);
    }
    
    // Deuxième étape : filtrer les profils en fonction des mots-clés d'exclusion
    filteredResults = filteredResults.filter(liker => {
      if (!liker.job) return true; // Garder ceux sans info de job
      
      const jobTitle = liker.job.toLowerCase();
      
      // Exclure les profils qui contiennent 'eskimoz' ou les émojis flocon de neige (hardcodé pour compatibilité)
      if (jobTitle.includes('eskimoz') || jobTitle.includes('❄️') || jobTitle.includes('❄')) {
        return false;
      }
      
      // Exclure les profils qui contiennent un des mots-clés d'exclusion
      if (excludeKeywords && excludeKeywords.length > 0) {
        // Vérifier si le titre du poste contient un des mots-clés d'exclusion
        return !excludeKeywords.some(keyword => {
          const keywordRegex = new RegExp(`\\b${keyword.toLowerCase()}\\b`);
          return keywordRegex.test(jobTitle);
        });
      }
      
      return true;
    });
    
    // Si pas de mots-clés d'inclusion dans la DB, montrer les profils filtrés jusqu'ici
    if (!keywords || !keywords.length) return filteredResults;
    
    // Troisième étape : appliquer le filtre des mots-clés d'inclusion sur les profils restants
    return filteredResults.filter(liker => {
      if (!liker.job) return false;
      
      const jobTitle = liker.job.toLowerCase();
      // Vérifier si un des mots-clés d'inclusion correspond au titre du poste
      return keywords.some(keyword => {
        const keywordRegex = new RegExp(`\\b${keyword.toLowerCase()}\\b`);
        return keywordRegex.test(jobTitle);
      });
    });
  }, [likers, keywords, excludeKeywords, favoriteFilter]);

  // Sort filtered likers
  const sortedLikers = useMemo(() => {
    return [...filteredLikers].sort((a, b) => {
      // Ne plus afficher automatiquement les favoris en haut
      if (orderBy === 'numberOfLikes') {
        return order === 'asc' ? a.numberOfLikes - b.numberOfLikes : b.numberOfLikes - a.numberOfLikes;
      } else if (orderBy === 'lastLikeDate') {
        return order === 'asc' 
          ? new Date(a.lastLikeDate) - new Date(b.lastLikeDate)
          : new Date(b.lastLikeDate) - new Date(a.lastLikeDate);
      } else if (orderBy === 'name') {
        return order === 'asc'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      } else if (orderBy === 'job') {
        return order === 'asc'
          ? a.job.localeCompare(b.job)
          : b.job.localeCompare(a.job);
      }
      return 0;
    });
  }, [filteredLikers, orderBy, order]);

  // Pagination des likers triés
  const paginatedLikers = useMemo(() => {
    return sortedLikers.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  }, [sortedLikers, page, rowsPerPage]);


  const getConnection = (liker) => {
    return connections.find(connection => 
      connection.connexionName === liker.name
    );
  };

  const isConnected = (liker) => {
    return !!getConnection(liker);
  };

  const hasMessage = (liker) => {
    const connection = getConnection(liker);
    return connection && connection.messages && connection.messages.length > 0;
  };

  const handleHideConfirm = (liker) => {
    setConfirmDialog({ open: true, liker });
  };

  const handleHideCancel = () => {
    setConfirmDialog({ open: false, liker: null });
  };

  const handleHideLiker = async () => {
    const liker = confirmDialog.liker;
    if (!liker || !liker.linkedinUrl) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/sales/likers/${linkedinId}/hide`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          linkedinUrl: liker.linkedinUrl
        })
      });

      if (!response.ok) {
        throw new Error('Failed to hide liker');
      }

      // Fermer la boîte de dialogue et rafraîchir les données
      setConfirmDialog({ open: false, liker: null });
      await fetchLikers();
    } catch (error) {
      console.error('Error hiding liker:', error);
    }
  };
  
  // Fonction pour marquer/démarquer un liker comme favori
  const handleToggleFavorite = async (liker) => {
    if (!liker || !liker._id) return;
    
    try {
      const newFavoriteStatus = !liker.favorite;
      
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/sales/likers/${linkedinId}/favorite/${liker._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          favorite: newFavoriteStatus
        })
      });

      if (!response.ok) {
        throw new Error(`Failed to ${newFavoriteStatus ? 'favorite' : 'unfavorite'} liker`);
      }

      // Rafraîchir les données
      await fetchLikers();
    } catch (error) {
      console.error('Error toggling favorite status:', error);
    }
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenMessageDialog = async (liker) => {
    // Ouvrir la modale immédiatement
    setMessageDialog({
      open: true,
      liker,
      message: '',
      isConnected: isConnected(liker)
    });
    
    // Démarrer le bot LinkedIn et attendre la réponse du serveur
    try {
      console.log('Starting LinkedIn bot...');
      setBotLoading(true); // Afficher l'indicateur de chargement
      
      // Appel direct à l'API /start
      const response = await fetch(`${apiUrl}/api/linkedin/start`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ userId: linkedinId })
      });
      
      const data = await response.json();
      setBotLoading(false); // Masquer l'indicateur de chargement
      
      if (!response.ok || !data.success) {
        throw new Error(data.message || 'Failed to start LinkedIn bot');
      }
      
      console.log('Bot started successfully, ready to send messages');
    } catch (error) {
      console.error('Error starting LinkedIn bot:', error);
      setBotLoading(false); // S'assurer que l'indicateur de chargement est masqué en cas d'erreur
      
      setSnackbar({
        open: true,
        message: 'Error starting LinkedIn bot. You can still try to send a message.',
        severity: 'warning'
      });
    }
  };

  const handleCloseMessageDialog = () => {
    setMessageDialog({
      open: false,
      liker: null,
      message: '',
      isConnected: false
    });
  };

  const handleMessageChange = (event) => {
    setMessageDialog(prev => ({
      ...prev,
      message: event.target.value
    }));
  };

  const handleSendMessageClick = () => {
    setConfirmMessageDialog({
      open: true,
      liker: messageDialog.liker,
      message: messageDialog.message
    });
    setMessageDialog(prev => ({ ...prev, open: false }));
  };

  const handleConfirmSendMessage = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${apiUrl}/api/sales/send-message`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
        body: JSON.stringify({
        userId: linkedinId,
          connexionUrl: confirmMessageDialog.liker.linkedinUrl,
          message: confirmMessageDialog.message
        })
      });
      
      const data = await response.json();
      
      if (!response.ok) {
        console.error('Error response:', data);
        throw new Error(data.message || 'Error sending message');
      }
      
      console.log('Message sent successfully:', data);
      
      // Fermer la modale et rafraîchir les connexions
      setConfirmMessageDialog({ open: false, liker: null, message: '' });
      await fetchConnections();
      
      // Show success message
      setSnackbar({
        open: true,
        message: 'Message sent successfully',
        severity: 'success'
      });
      
      // Fermer la boîte de dialogue
      handleCloseMessageDialog();
    } catch (error) {
      console.error('Error sending message:', error);
      setSnackbar({
        open: true,
        message: `Error sending message: ${error.message}`,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCancelSendMessage = () => {
    setMessageDialog(prev => ({
      ...prev,
      open: true
    }));
    setConfirmMessageDialog({ open: false, liker: null, message: '' });
  };

  const handleCopyMessage = (content) => {
    setMessageDialog(prev => ({
      ...prev,
      message: content
    }));
  };

  // Slice connections for current page
  const displayedConnections = connections.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleFavoritePageChange = (event, newPage) => {
    loadFavoriteMessages(newPage + 1);
  };

  // Mettre à jour les statistiques de l'utilisateur quand les connexions changent
  useEffect(() => {
    // Calculer le nombre de contacts potentiels
    const potentialContacts = connections.length;
    
    // Calculer le nombre de contacts avec au moins un message
    const firstMessagesSent = connections.filter(conn => 
      conn.messages && conn.messages.length > 0
    ).length;
    
    // Mettre à jour les stats
    setUserStats({
      firstMessagesSent,
      potentialContacts
    });
  }, [connections]);

  // Récupérer les statistiques de l'utilisateur
  const fetchUserStats = async () => {
    try {
      // Calculer le nombre de contacts potentiels
      const potentialContacts = connections.length;
      
      // Calculer le nombre de contacts avec au moins un message
      const firstMessagesSent = connections.filter(conn => 
        conn.messages && conn.messages.length > 0
      ).length;
      
      // Mettre à jour les stats
      setUserStats({
        firstMessagesSent,
        potentialContacts
      });
    } catch (error) {
      console.error('Error calculating user stats:', error);
    }
  };

  // Formater le score de ventes
  const formatSalesScore = (firstMessagesSent, potentialContacts) => {
    if (potentialContacts === 0) return '0/0';
    return `${firstMessagesSent}/${potentialContacts}`;
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const renderMessageDialog = () => (
    <Dialog 
      open={messageDialog.open} 
      onClose={handleCloseMessageDialog}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        {messageDialog.isConnected ? "Send Message" : "Connection required"}
      </DialogTitle>
      <DialogContent>
        {botLoading ? (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
            <CircularProgress style={{ marginBottom: '15px' }} />
            <Typography variant="body1">
              Starting LinkedIn services...
            </Typography>
          </div>
        ) : !messageDialog.isConnected ? (
          <Typography>You must be connected with this person on LinkedIn to send them a message.</Typography>
        ) : (
          <>
            <TextField
              autoFocus
              margin="dense"
              label="Message"
              type="text"
              fullWidth
              multiline
              rows={4}
              value={messageDialog.message}
              onChange={handleMessageChange}
              disabled={loading}
            />
            {connections.length > 0 && (
              <div style={{ marginTop: '20px' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                  <Tabs 
                    value={messageType} 
                    onChange={(e, newValue) => setMessageType(newValue)}
                    aria-label="message types"
                  >
                    <Tab 
                      label="Recent messages" 
                      value="recent"
                    />
                    <Tab 
                      label="Favorite messages" 
                      value="favorite"
                    />
                  </Tabs>
                </Box>
                <div style={{ 
                  maxHeight: '200px', 
                  overflowY: 'auto',
                  border: '1px solid #e0e0e0',
                  borderRadius: '4px',
                  padding: '10px'
                }}>
                  {(messageType === 'recent' ? getRecentMessages() : favoriteMessages).map((msg, index) => (
                    
                    <div
                      key={index}
                      style={{
                        padding: '8px',
                        margin: '4px 0',
                        borderRadius: '4px',
                        backgroundColor: '#f5f5f5',
                        position: 'relative'
                      }}
                    >
                      <div 
                        onClick={() => handleCopyMessage(msg.content)}
                        style={{
                          cursor: 'pointer',
                          paddingRight: '40px'
                        }}
                      >
                        <Typography variant="body2" color="textSecondary" style={{ fontSize: '0.8rem' }}>
                          Send to {msg.connexionName} on {new Date(msg.sentAt).toLocaleDateString()}
                        </Typography>
                        <Typography variant="body1" style={{ marginTop: '4px' }}>
                          {msg.content}
                        </Typography>
                      </div>
                      <IconButton
                        onClick={() => {
                          // Déterminer l'ID correct à utiliser (préférer _id pour la cohérence)
                          const messageId = msg._id || msg.id;
                          console.log('Clicking favorite for message with ID:', messageId, 'Current status:', msg.favorite);
                          toggleFavorite(msg.connexionUrl, messageId);
                        }}
                        style={{
                          position: 'absolute',
                          right: '8px',
                          top: '50%',
                          transform: 'translateY(-50%)'
                        }}
                        size="small"
                        title={msg.favorite ? "Remove from favorites" : "Add to favorites"}
                      >                          
                        {msg.favorite ? (
                          <StarIcon style={{ color: '#ffc107' }} />
                        ) : (
                          <StarBorderIcon />
                        )}
                      </IconButton>
                    </div>
                  ))}
                </div>
                {messageType === 'favorite' && totalFavoritePages > 1 && (
                  <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                    <Pagination
                      count={totalFavoritePages}
                      page={favoriteMessagesPage}
                      onChange={handleFavoritePageChange}
                      color="primary"
                    />
                  </Box>
                )}
              </div>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseMessageDialog} disabled={loading || botLoading}>
          {messageDialog.isConnected ? "Cancel" : "Close"}
        </Button>
        {messageDialog.isConnected && (
          <Button 
            onClick={handleSendMessageClick}
            variant="contained" 
            color="primary"
            disabled={!messageDialog.message.trim() || loading || botLoading}
          >
            {loading ? <CircularProgress size={24} /> : "Send"}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );

  // Gérer le changement de filtre des favoris
  const handleFavoriteFilterChange = (event) => {
    setFavoriteFilter(event.target.value);
    setPage(0); // Réinitialiser la pagination lors du changement de filtre
  };


  return (
    <div className="sales-container">
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <h1>Sales</h1>
        <div style={{ 
          backgroundColor: '#f5f5f5', 
          padding: '8px 16px', 
          borderRadius: '4px', 
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          display: 'flex',
          alignItems: 'center'
        }}>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            Score: {formatSalesScore(userStats.firstMessagesSent, sortedLikers.length)}
          </Typography>
        </div>
      </div>

      <div className="filter-controls">
        <FormControl variant="outlined" size="small" style={{ minWidth: 200, marginBottom: 20 }}>
          <InputLabel id="favorite-filter-label">Display</InputLabel>
          <Select
            labelId="favorite-filter-label"
            value={favoriteFilter}
            onChange={handleFavoriteFilterChange}
            label="Display"
          >
            <MenuItem value="all">All profiles</MenuItem>
            <MenuItem value="favorites">Favorites only</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div className="table-container">
        <Table>
          <TableHead>
            <TableRow className="table-header">
              <TableCell className="table-cell header-cell profile-cell">
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={() => handleSort('name')}
                >
                  Profile
                </TableSortLabel>
              </TableCell>
              <TableCell className="table-cell header-cell job-cell">
                <TableSortLabel
                  active={orderBy === 'job'}
                  direction={orderBy === 'job' ? order : 'asc'}
                  onClick={() => handleSort('job')}
                >
                  Job Title
                </TableSortLabel>
              </TableCell>
              <TableCell className="table-cell header-cell linkedin-cell" align="center">LinkedIn</TableCell>
              <TableCell className="table-cell header-cell likes-cell" align="center">
                <TableSortLabel
                  active={orderBy === 'numberOfLikes'}
                  direction={orderBy === 'numberOfLikes' ? order : 'asc'}
                  onClick={() => handleSort('numberOfLikes')}
                >
                  Number of Likes
                </TableSortLabel>
              </TableCell>
              <TableCell className="table-cell header-cell date-cell">
                <TableSortLabel
                  active={orderBy === 'lastLikeDate'}
                  direction={orderBy === 'lastLikeDate' ? order : 'asc'}
                  onClick={() => handleSort('lastLikeDate')}
                >
                  Last Like
                </TableSortLabel>
              </TableCell>
              <TableCell className="table-cell header-cell actions-cell" align="center">HIDDEN</TableCell>
              <TableCell className="table-cell header-cell message-cell" align="center">MESSAGE</TableCell>
              <TableCell className="table-cell header-cell favorite-cell" align="center" style={{ width: '50px' }}>
                Favorite
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedLikers.map((liker, index) => (
              <TableRow key={liker.linkedinUrl || index} className="table-row">
                <TableCell className="table-cell profile-cell">
                  <div className="user-info">
                    {liker.photo ? (
                      <Avatar src={liker.photo} className="avatar" />
                    ) : (
                      <Avatar className="avatar">
                        <PersonIcon />
                      </Avatar>
                    )}
                    <span>{liker.name}</span>
                  </div>
                </TableCell>
                <TableCell className="table-cell job-cell">{liker.job}</TableCell>
                <TableCell className="table-cell linkedin-cell" align="center">
                  <Link 
                    href={liker.linkedinUrl} 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    <img src={linkedinIcon} alt="LinkedIn" className="linkedin-icon" />
                  </Link>
                </TableCell>
                <TableCell className="table-cell likes-cell" align="center">{liker.numberOfLikes}</TableCell>
                <TableCell className="table-cell date-cell">
                  <span className="date">
                    {formatDate(liker.lastLikeDate)}
                  </span>
                </TableCell>
                <TableCell className="table-cell actions-cell" align="center">
                  <IconButton
                    onClick={() => handleHideConfirm(liker)}
                    className="hide-button"
                    color="primary"
                    title="Hide"
                  >
                    <VisibilityOffIcon />
                  </IconButton>
                </TableCell>
                <TableCell className="table-cell message-cell" align="center">
                  <IconButton
                    onClick={() => handleOpenMessageDialog(liker)}
                    color={isConnected(liker) ? "primary" : "default"}
                    title={isConnected(liker) 
                      ? "Send a message" 
                      : "You must be connected to send a message"
                    }
                  >
                  <div className="message-icon d-flex align-items-center">
                    <EmailIcon />
                    {
                      hasMessage(liker) ? (
                        <CheckCircleIcon 
                          color="success" 
                          style={{ fontSize: '0.75rem',position: 'absolute', left: '2.5rem' }}
                          titleAccess="Message sent"
                        />
                      ) : (
                        <CancelIcon 
                          color="error"
                          fontSize="inherit"
                          style={{ fontSize: '0.75rem',position: 'absolute', left: '2.5rem' }}
                          titleAccess="No message sent yet"
                        />
                      )
                    }
                  </div>
                  </IconButton>
                </TableCell>
                <TableCell className="table-cell favorite-cell" align="center">
                  <IconButton 
                    onClick={() => handleToggleFavorite(liker)}
                    size="small"
                    color={liker.favorite ? "primary" : "default"}
                  >
                    {liker.favorite ? <StarIcon style={{ color: '#FFD700' }} /> : <StarBorderIcon />}
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={sortedLikers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100]}
          labelRowsPerPage="Rows per page:"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
        />
      </div>

      <Dialog
        open={confirmDialog.open}
        onClose={handleHideCancel}
        className="hide-dialog"
      >
        <DialogTitle>Confirm Hide</DialogTitle>
        <DialogContent>
          Are you sure you want to hide {confirmDialog.liker?.name}?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleHideCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleHideLiker} color="primary" variant="contained">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {renderMessageDialog()}

      <Dialog
        open={confirmMessageDialog.open}
        onClose={handleCancelSendMessage}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Message preview
        </DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            To : {confirmMessageDialog.liker?.name}
          </Typography>
          <Typography
            variant="body2"
            style={{
              backgroundColor: '#f5f5f5',
              padding: '16px',
              borderRadius: '4px',
              marginTop: '8px',
              whiteSpace: 'pre-wrap'
            }}
          >
            {confirmMessageDialog.message}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelSendMessage}>
            Back
          </Button>
          <Button 
            onClick={handleConfirmSendMessage}
            variant="contained"
            color="primary"
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Send Message"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Sales;
