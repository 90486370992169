import React, { useState, useRef, useEffect } from 'react';
import './css/selecteur.css';

const DropdownDate = ({ label, options, onSelect, selectedValue }) => {
    const [isActive, setIsActive] = useState(false);
    //console.log(selectedValue)
    const handleSelect = (option) => {
        onSelect(option.value);
        setIsActive(false);
    };
    return (
        <div className="dropdown-container">
            <div
                className={`dropdown-selected ${isActive ? 'active' : ''}`}
                onClick={() => setIsActive(!isActive)}
            >
                {selectedValue ? options.find(option => option.value === selectedValue)?.label :  label}
                <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 8L10 13L15 8" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
            {isActive && (
                <div className={`dropdown-list ${isActive ? 'active' : ''}`}>
                    {options.map(option => (
                        <div
                            key={option.value}
                            data-value={option.value}
                            onClick={() => handleSelect(option)}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

const Dropdown = ({ label, options, onSelect }) => {
    const [isActive, setIsActive] = useState(false);
    const [selected, setSelected] = useState(label);

    const handleSelect = (option) => {
        setSelected(option.label);
        setIsActive(false);
        onSelect(option.value); 
    };

    return (
        <div className="dropdown-container">
            <div 
                className={`dropdown-selected ${isActive ? 'active' : ''}`} 
                onClick={() => setIsActive(!isActive)}
            >
                {selected}
                <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 8L10 13L15 8" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
            <div className={`dropdown-list ${isActive ? 'active' : ''}`}>
                {options.map(option => (
                    <div 
                        key={option.value} 
                        data-value={option.value}
                        onClick={() => handleSelect(option)}
                    >
                        {option.label}
                    </div>
                ))}
            </div>
        </div>
    );
};

const DropdownUser = ({ label, options, onSelect, value, normalizeSearch }) => {
    const [isActive, setIsActive] = useState(false);
    const [inputValue, setInputValue] = useState('');

    // Trouver le label correspondant à la valeur actuelle
    const currentLabel = options.find(opt => opt.value === value)?.label || label;

    const handleSelect = (option) => {
        setIsActive(false);
        onSelect(option.value);
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const filteredOptions = options.filter(option => {
        if (option.value === "all") return true;
        const normalizedInput = normalizeSearch(inputValue);
        return option.searchString.includes(normalizedInput);
    });

    return (
        <div className="dropdown-container">
            <div 
                className={`dropdown-selected ${isActive ? 'active' : ''}`} 
                onClick={() => setIsActive(!isActive)}
            >
                {currentLabel}
                <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5 8L10 13L15 8" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
            {isActive && (
                <div className={`dropdown-list ${isActive ? 'active' : ''}`}>
                    <input 
                        type="text" 
                        value={inputValue} 
                        onChange={handleInputChange} 
                        placeholder="Write here..." 
                        className="dropdown-search"
                        onClick={(e) => e.stopPropagation()}
                    />
                    {filteredOptions.map(option => (
                        <div
                            key={option.value}
                            className="dropdown-item"
                            onClick={() => handleSelect(option)}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};


export { Dropdown, DropdownUser, DropdownDate };